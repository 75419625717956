import React from 'react'

export default class Aside extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tabs: props.tabs,
      activeTabIndex: props.activeTabIndex,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _renderTabs(tabs, activeTabIndex) {
    return tabs.map((tab, index) => {
      let className = index === activeTabIndex ? 'current' : 'pending'
      return (
        <a
          onClick={() => this.props.onClick(tab, index)}
          className="kt-wizard-v2__nav-item "
          data-ktwizard-type="step"
          data-ktwizard-state={className}
        >
          <div class="kt-wizard-v2__nav-body">
            <div class="kt-wizard-v2__nav-icon">
              {this._renderIcons(tab.icon)}
            </div>
            <div class="kt-wizard-v2__nav-label">
              <div class="kt-wizard-v2__nav-label-title">{tab.title}</div>
              <div class="kt-wizard-v2__nav-label-desc">{tab.subtitle}</div>
            </div>
          </div>
        </a>
      )
    })
  }

  _renderIcons(icon) {
    return <i class={`flaticon-${icon}`}></i>
  }

  render() {
    let { tabs, activeTabIndex } = this.state

    return (
      <>
        <div class="kt-grid__item kt-wizard-v2__aside">
          <div class="kt-wizard-v2__nav">
            <div class="kt-wizard-v2__nav-items">
              {this._renderTabs(tabs, activeTabIndex)}
            </div>
          </div>
        </div>
      </>
    )
  }
}
