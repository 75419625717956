import React from "react";

import moment from "moment";

import { withRouter } from "react-router-dom";

import BaseTable from "./BaseTable";

import PillCell from "./cells/PillCell";

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Currency from "../../../utils/Currency";

import Notify from "../../../utils/Notify";

const FILTERS = [
  {
    label: "All",
    value: null,
  },
  {
    label: "Individual",
    value: "individual",
  },
  {
    label: "Charity",
    value: "charity",
  },
  {
    label: "Restaurant",
    value: "restaurant",
  },
];

const ORDER_STATUS_FILTERS = {
  name: {
    api: "status",
    display: "Status",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Out For Delivery",
      value: "out_for_delivery",
    },
    {
      label: "Completed",
      value: "completed",
    },
    {
      label: "Cancelled",
      value: "cancelled",
    },
  ],
};

class OrdersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingAccount: true,
    };

    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _getColumns() {
    return [
      {
        Header: "INVOICE NO.",
        id: "invoice_no",
        accessor: (order) => order.id,
      },
      {
        Header: "Date",
        id: "date",
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          return moment(order.created_at).format("DD MMM YYYY");
        },
      },
      {
        Header: "TIME",
        id: "date",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          return moment(order.created_at).format("hh:mm");
        },
      },
      {
        Header: "Customer",
        id: "Customer",
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          return `${order.client.user.first_name} ${order.client.user.last_name}`;
        },
      },
      {
        Header: "Status",
        id: "status",
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          let className = "label-light-success";
          if (order.status === "pending") {
            className = "label-light-primary";
          }
          if (order.status === "cancelled") {
            className = "label-light-danger";
          }
          if (order.status === "out_for_delivery") {
            className = "label-light-info";
          }

          return (
            <PillCell
              text={General.snakeCaseToTitleCase(order.status)}
              className={className}
            />
          );
        },
      },
      {
        Header: "Actions",
        id: "status",
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          return (
            <a
              href={`/orders/${order.id}`}
              class="btn btn-sm btn-pill btn-outline-primary mr-2 w-100"
              title="View"
            >
              <span class="svg-icon svg-icon-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M3,12 C3,12 5.45454545,6 12,6 C16.9090909,6 21,12 21,12 C21,12 16.9090909,18 12,18 C5.45454545,18 3,12 3,12 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                    ></path>
                    <path
                      d="M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z"
                      fill="#000000"
                      opacity="0.3"
                    ></path>
                  </g>
                </svg>
              </span>
              View Details
            </a>
          );
        },
      },
    ];
  }

  render() {
    let { loading } = this.state;

    const columns = this._getColumns();

    return (
      <div>
        <BaseTable
          ref={this.table}
          search={false}
          title={this.props.title}
          endpoint={window.Api.Orders}
          noDataMessage={"No orders found"}
          title={this.props.title}
          columns={columns}
          filters={[ORDER_STATUS_FILTERS]}
          exportButtonsEnabled={false}
          showObjectFilter={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
        />
      </div>
    );
  }
}

OrdersTable.defaultProps = {
  title: "All Orders",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(OrdersTable);
