
export default class Website {
  static getDomain(website){
    let url = Website.getCustomDomain(website) || Website.getNativeDomain(website)
    return url
  }

  static getCustomDomain(website){
    if(!website.url){
      return null
    }

    if(website.url.startsWith("http://") || website.url.startsWith("https://")){
      return website.url
    }
    return "http://" + website.url
  }

  static getNativeDomain(website){
    return "http://" + website.slug + "." + window.General.WebsiteNakedDomain
  }

  static getCleanDomain(website){
    let url = Website.getDomain(website)
    url = url.replace("https://", "").replace("http://", "")
    return url
  }
}
