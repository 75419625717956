import React from 'react'

import Aside from './components/Aside'
import ProfileSettings from './components/settings/ProfileSettings'
import AccountSettings from './components/settings/AccountSettings'
import SecuritySettings from './components/settings/SecuritySettings'
import NotificationSettings from './components/settings/NotificationSettings'
import StaffSettings from './components/settings/StaffSettings'

import Backend from '../utils/Backend'

const TABS = [
  {
    title: 'Profile Settings',
    subtitle: 'Control your basic default settings',
    icon: 'profile',
  },
  {
    title: 'Account Settings',
    subtitle: 'Control your login details',
    icon: 'user-settings',
  },
  {
    title: 'Security',
    subtitle: 'Protect your account',
    icon: 'lock',
  },
  {
    title: 'Notifications',
    subtitle: 'Control when you will be notified',
    icon: 'alert',
  },
  {
    title: 'Staff Settings',
    subtitle: 'Manage your staff roles',
    icon: 'users',
  },
]

export default class Settings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTabIndex: 0,
    }
  }

  _renderContent(tabIndex) {
    switch (tabIndex) {
      case 0:
        return <ProfileSettings />
      case 1:
        return <AccountSettings />
      case 2:
        return <SecuritySettings />
      case 3:
        return <NotificationSettings />
      case 4:
        return <StaffSettings />
      default:
        return null
    }
  }

  render() {
    let { activeTabIndex } = this.state

    return (
      <div className="kt-portlet">
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div
            className="kt-grid  kt-wizard-v2 kt-wizard-v2--white"
            id="kt_wizard_v2"
            data-ktwizard-state="step-first"
          >
            <Aside
              tabs={TABS}
              activeTabIndex={activeTabIndex}
              onClick={(tab, index) => {
                return this.setState({ activeTabIndex: index })
              }}
            />
            <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
              <form className="kt-form" id="kt_form">
                {this._renderContent(activeTabIndex)}
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
