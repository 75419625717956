import React from "react";

import moment from "moment";

import { withRouter } from "react-router-dom";

import BaseTable from "./BaseTable";
import QrCodesTable from "./QrCodesTable";

import FeesModal from "../modal/FeesModal";
import ConfirmModal from "../modal/ConfirmModal";

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Currency from "../../../utils/Currency";

import Notify from "../../../utils/Notify";

const SHOW_ADVANCED = false;

const USER_STATUS_FILTERS = {
  name: {
    api: "status",
    display: "Status",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "suspended",
    },
  ],
};

const USER_ROLE_FILTERS = {
  name: {
    api: "role",
    display: "Type",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Individual",
      value: "client",
    },
    {
      label: "Charity",
      value: "charity",
    },
    {
      label: "Restaurant",
      value: "restaurant",
    },
  ],
};

const KYC_STATUS_FILTERS = {
  name: {
    api: "stripe_kyc_status",
    display: "KYC Status",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Verified",
      value: "verified",
    },
    {
      label: "Unverified",
      value: "unverified",
    },
    {
      label: "Pending",
      value: "Pending",
    },
  ],
};

const PAYMENTS_STATUS_FILTERS = {
  name: {
    api: "stripe_charges_enabled",
    display: "Payments Enabled",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Yes",
      value: "true",
    },
    {
      label: "No",
      value: "false",
    },
  ],
};

const PAYOUTS_STATUS_FILTERS = {
  name: {
    api: "stripe_payouts_enabled",
    display: "Payouts Enabled",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Yes",
      value: "true",
    },
    {
      label: "No",
      value: "false",
    },
  ],
};

class UsersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingAccount: true,
    };

    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _updateClient(client) {
    let data = {
      user: {
        status: client.user.status === "active" ? "suspended" : "active",
      },
    };
    this.setState({ isUpdating: true });
    Backend.updateClient(client, data)
      .then(() => {
        Notify.success("User Status Updated!");
        this.table.current.refresh();
        this.setState({
          isUpdating: false,
          selectedClient: null,
          showConfirmPauseModal: false,
        });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isUpdating: false });
      });
  }

  _getConfirmPauseMessage(selectedClient) {
    if (!selectedClient) {
      return null;
    }
    if (selectedClient.user.status === "active") {
      return "Pausing a users account will revoke access to Xelda";
    }
    return "Unpausing a users account will grant access to Xelda";
  }

  _getConfirmPauseButtonText(client) {
    if (!client) {
      return null;
    }
    if (client.user.status === "active") {
      return "Yes, pause";
    }
    return "Yes, unpause";
  }

  _getColumns() {
    return [
      {
        Header: "Name",
        id: "user.first_name",
        Cell: (rowInfo) => {
          let client = rowInfo.original;
          let name = `${client.user.first_name} ${client.user.last_name}`;

          return (
            <>
              <span class="text-dark-75 font-weight-bold line-height-sm">
                {name}
              </span>
              <div class="font-size-sm text-dark-50 text-hover-primary">
                {client.user.email}
              </div>
            </>
          );
        },
      },
      {
        Header: "User Status",
        id: "user.status",
        Cell: (rowInfo) => {
          let client = rowInfo.original;

          let labelClass = "label-primary";
          let status = "Active";
          if (client.user.status !== "active") {
            labelClass = "label-danger";
            status = "Inactive";
          }

          let lastSeen = client.user.last_seen || client.user.last_login;

          return (
            <>
              <span class={`label ${labelClass} label-inline label-pill`}>
                {status}
              </span>
              <div class="font-size-sm text-dark-50 text-hover-primary">
                Last login:{" "}
                {moment(lastSeen).format("DD/MMM/YYYY").toUpperCase()}
              </div>
            </>
          );
        },
      },
      {
        Header: "KYC status",
        id: "stripe_kyc_status",
        show: SHOW_ADVANCED,
        Cell: (rowInfo) => {
          let client = rowInfo.original;

          let labelClass = "label-primary";
          let status = "Verified";
          if (!client.stripe_kyc_status === "Pending") {
            labelClass = "label-info";
            status = "Pending";
          }
          if (!client.stripe_kyc_status !== "unverified") {
            labelClass = "label-danger";
            status = "Unverified";
          }

          return status;

          return (
            <span class={`label ${labelClass} label-inline label-pill`}>
              {status}
            </span>
          );
        },
      },
      {
        Header: "Payments",
        id: "stripe_charges_enabled",
        show: SHOW_ADVANCED,
        Cell: (rowInfo) => {
          let client = rowInfo.original;

          let labelClass = "label-primary";
          let status = "Enabled";
          if (!client.stripe_charges_enabled) {
            labelClass = "label-danger";
            status = "Disabled";
          }

          return status;

          return (
            <span class={`label ${labelClass} label-inline label-pill`}>
              {status}
            </span>
          );
        },
      },
      {
        Header: "Payouts",
        id: "stripe_payouts_enabled",
        show: SHOW_ADVANCED,
        Cell: (rowInfo) => {
          let client = rowInfo.original;

          let labelClass = "label-primary";
          let status = "Enabled";
          if (!client.stripe_payouts_enabled) {
            labelClass = "label-danger";
            status = "Disabled";
          }

          return status;

          return (
            <span class={`label ${labelClass} label-inline label-pill`}>
              {status}
            </span>
          );
        },
      },
      {
        Header: "Type",
        id: "role",
        Cell: (rowInfo) => {
          let client = rowInfo.original;

          let labelClass = "label-danger";
          let textClass = "text-danger";
          let type = "Individual";
          return (
            <>
              <span class={`label ${labelClass} label-dot`}></span>
              &nbsp;
              <span class={`font-weight-bold ${textClass}`}>{type}</span>
            </>
          );
        },
      },
      {
        Header: "Total Earned",
        id: "total_earnings",
        Cell: (rowInfo) => {
          let client = rowInfo.original;
          let code = client.default_qr?.currency.code || "";
          return (
            <>
              <span class="text-dark-75 font-weight-bold line-height-sm">
                {Currency.format(client.total_earnings, code)}
              </span>
              {code && (
                <div class="font-size-sm text-dark-50 text-hover-primary">
                  {code.toUpperCase()}
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: "Default Shift Duration",
        id: "default_shift_duration",
        Cell: (rowInfo) => {
          let client = rowInfo.original;
          let duration = client.default_shift_duration || "-";
          return duration
        },
      },
      {
        Header: "Actions",
        orderable: false,
        id: "status",
        Cell: (rowInfo) => {
          let client = rowInfo.original;
          let className =
            client.user.status === "active"
              ? "label-light-success"
              : "label-light-danger";

          return (
            <div className="dropdown">
              <a
                href="javascript:;"
                className="btn btn-sm btn-clean btn-icon btn-icon-md"
                data-toggle="dropdown"
              >
                <i className="la la-ellipsis-v"></i>
              </a>

              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <ul className="navi flex-column navi-hover py-2">
                  <li class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                    Choose an action:
                  </li>
                  <li class="navi-item">
                    <a
                      href="#"
                      class="navi-link"
                      onClick={() =>
                        this.setState({
                          selectedClient: client,
                          showFeesModal: true,
                        })
                      }
                    >
                      <span class="navi-icon">
                        <i class="la la-money-check-alt"></i>
                      </span>
                      <span class="navi-text">Manage Fees</span>
                    </a>
                  </li>
                  {/*
                  <li class="navi-item">
                    <a
                      href="#"
                      class="navi-link"
                    >
                      <span class="navi-icon">
                        <i class="la fab la-wpforms"></i>
                      </span>
                      <span class="navi-text">Enable KYC</span>
                    </a>
                  </li>
                  */}
                  <li class="navi-item">
                    <a
                      href="#"
                      class="navi-link"
                      onClick={() =>
                        this.setState({
                          selectedClient: client,
                          showConfirmPauseModal: true,
                        })
                      }
                    >
                      <span class="navi-icon">
                        <i class="la la-pause-circle-o"></i>
                      </span>
                      <span class="navi-text">
                        {client.user.status === "active" ? "Pause" : "Unpause"}{" "}
                        Account
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          );
        },
      },
    ];
  }

  _renderQrCodes(client) {
    return (
      <QrCodesTable client={client} showPaginationTop={false} title={``} />
    );
  }

  render() {
    let {
      loading,
      isUpdating,
      selectedClient,
      showFeesModal,
      showConfirmPauseModal,
    } = this.state;

    const columns = this._getColumns();

    let tableProps = {};
    if (this.props.showQrCodesSubTable) {
      tableProps = {
        SubComponent: (row) => this._renderQrCodes(row.original),
      };
    }

    return (
      <div>
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          endpoint={window.Api.Clients}
          noDataMessage={"No users found"}
          title={this.props.title}
          columns={columns}
          filters={[USER_STATUS_FILTERS, USER_ROLE_FILTERS]}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
        />
        <FeesModal
          show={showFeesModal}
          client={selectedClient}
          onHide={() => {
            this.setState({ selectedClient: null, showFeesModal: false });
          }}
          onUpdated={() => {
            //this.setState({ selectedClient: null, showFeesModal: false });
          }}
        />
        <ConfirmModal
          show={showConfirmPauseModal}
          loading={isUpdating}
          message={this._getConfirmPauseMessage(selectedClient)}
          cancelButtonText="No, cancel"
          confirmButtonText={this._getConfirmPauseButtonText(selectedClient)}
          onCancel={() =>
            this.setState({
              selectedClient: null,
              showConfirmPauseModal: false,
            })
          }
          onConfirm={() => this._updateClient(selectedClient)}
        />
      </div>
    );
  }
}

UsersTable.defaultProps = {
  title: "All Users",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(UsersTable);
