import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Fees from "../Fees"

import AddGroupFeesModal from "./AddGroupFeesModal"
import ConfirmModal from "./ConfirmModal"

import Backend from "../../../utils/Backend"
import General from "../../../utils/General"
import Notify from "../../../utils/Notify"
import Currency from "../../../utils/Currency"


const CLIENT_FEES = [
  {
    title: "Charge for person giving payment",
    base_key: "platform_transaction_tiper"
  },
  {
    title: "Payee Transaction Fee",
    base_key: "platform_transaction_tipee"
  },
  {
    title: "Monthly Fee",
    base_key: "platform_monthly"
  },
  {
    title: "Withdrawal Fee",
    base_key: "platform_payout"
  }
]

export default class FeesModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...props,
      allGroupFees: [],
      isLoading: false,
    }
  }

  componentDidMount(){
    this._load()
  }

  componentWillReceiveProps(nextProps){
    let refresh = false

    if(nextProps.qr !== this.state.qr){
      refresh = true
    }

    this.setState(nextProps, () => {
      if(refresh){
        this._load()
      }
    })
  }

  _load(){
    let {
      qr
    } = this.state

    if(!qr){
      return
    }

    this.setState({ isLoading: true })
    Backend.getAllGroupFees(qr)
    .then(allGroupFees => {
      allGroupFees.sort((a, b) => {
        if (a.default === b.default) {
          return a.min_tip - b.min_tip;
        }
        return a.default ? -1 : 1
      })

      this.setState({ allGroupFees, fees: allGroupFees[0], isLoading: false })
    })
    .catch(error => {
      Notify.error(error.message)
      this.props.onHide()
    })
  }

  _update(){
    let {
      fees,
    } = this.state

    this.setState({ isUpdating: true })
    Backend.updateGroupFees(fees)
    .then(fees => {
      this.setState({ fees, isUpdating: false })
      this.props.onUpdated()
      Notify.success("Pool Fees Updated!")
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ isUpdating: false })
    })
  }

  _deleteFees(fees){
    this.setState({ isUpdating: true })
    Backend.deleteGroupFees(fees)
    .then(fees => {
      this.setState({
        fees: null,
        isUpdating: false,
        showConfirmDeleteModal: false
      }, () => this._load())
      Notify.success("Successfully Deleted")
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ fees, isUpdating: false })
    })
  }

  _renderFeeInput(title, base_key){
    let { fees } = this.state

    let flat = fees[base_key+"_flat"] / 100
    let percentage = fees[base_key+"_percentage"] * 100

    return (
      <div className="form-qr row">
        <label className="col-4 col-form-label">{ title }</label>
        <div className="col-4">
          <div className="input-qr">
            <input
              type="text"
              className="form-control"
              defaultValue={flat}
              onChange={e => {
                flat = parseInt(e.target.value) * 100
                fees[base_key+"_flat"] = flat
                this.setState({ fees })
              }}
            />
            <div className="input-qr-append">
              <button type="button" className="btn btn-secondary">
                Flat
              </button>
            </div>
          </div>
        </div>

        <div className="col-4">
          <div className="input-qr">
            <input
              type="text"
              className="form-control"
              defaultValue={percentage}
              onChange={e => {
                percentage = parseInt(e.target.value) / 100
                fees[base_key+"_percentage"] = percentage
                this.setState({ fees })
              }}
            />
            <div className="input-qr-append">
              <button type="button" className="btn btn-secondary">
                Percentage
              </button>
            </div>
          </div>
        </div>
        <span className="form-text text-muted ml-auto">Leave blank for no charge.</span>
      </div>
    )
  }

  render() {
    let {
      show,
      fees,
      qr,
      allGroupFees,
      isLoading,
      isUpdating,
      showAddGroupFeesModal,
      showConfirmDeleteModal
    } = this.state

    if(!qr){
      return null
    }

    let currencyCode = qr.currency.code
    return (
      <>
        <Modal
          show={show}
          onHide={() => {
            if(!isLoading){
              this.props.onHide()
            }
          }}
          dialogClassName="modal-50vw"
        >
          <Modal.Header closeButton>
            <Modal.Title>Set Pool Fees</Modal.Title>
          </Modal.Header>


          <Modal.Body>
          { !isLoading &&
              <div className="row mb-5">
                <div className="col-md-2"/>
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-4">
                    </div>
                    <div className="col-md-4">
                      <select
                        className="form-control"
                        onChange={(e) => {
                          fees = allGroupFees.find(f => f.id == e.target.value)
                          this.setState({ fees });
                        }}
                        value={fees?.id}
                      >
                      {
                        allGroupFees.map(fees => {
                          let range = `${Currency.format(fees.min_tip, currencyCode)} - ${Currency.format(fees.max_tip, currencyCode)} `
                          return (
                            <option value={fees.id}>
                              {fees.default ? "Default" : range}
                            </option>
                          );
                        })
                      }
                      </select>
                    </div>
                    <div className="col-md-4">
                      <button
                        type="button"
                        className="btn btn-secondary generate-btn"
                        disabled={isUpdating}
                        onClick={() => this.setState({ showAddGroupFeesModal: true })}
                      >
                        <i className="flaticon2-plus"></i>
                        New Fees
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }
            <Fees
              key={fees?.id}
              fees={fees}
              onUpdated={fess => this.setState({ fees })}
              hint="Leave blank to use Global value"
            />
          </Modal.Body>

          <Modal.Footer>

            { fees &&
              <>
                {!fees.default &&
                   <a
                     href="javascript:;"
                     className="btn btn-outline-danger font-weight-bold ml-auto mr-3"
                     disabled={isUpdating}
                     onClick={() => this.setState({ showConfirmDeleteModal: true })}
                   >
                     Delete
                   </a>
                 }
                <button
                  type="button"
                  className={`btn btn-primary font-weight-bold ${fees?.default ? "ml-auto" : ""}`}
                  disabled={isUpdating}
                  onClick={() => this._update()}
                >
                  <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24"></polygon>
                          <path d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z" fill="#000000" fill-rule="nonzero" transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "></path>
                      </g>
                    </svg>
                  </span>
                  Save Changes
                </button>
              </>
            }

          </Modal.Footer>
        </Modal>
        <AddGroupFeesModal
          show={showAddGroupFeesModal}
          qr={qr}
          onHide={() => {
            this.setState({ showAddGroupFeesModal: false })
          }}
          onCreated={fees => {
            this.setState({
              showAddGroupFeesModal: false,
            })
            this._load()
          }}
        />
        <ConfirmModal
          show={showConfirmDeleteModal}
          loading={isUpdating}
          message={"You cannot undo this action"}
          cancelButtonText="No, Cancel"
          confirmButtonText={"Yes, Delete"}
          onCancel={() =>
            this.setState({ showConfirmDeleteModal: false })
          }
          onConfirm={() => this._deleteFees(fees)}
        />
      </>
    )
  }
}

FeesModal.defaultProps = {
    title: 'Are you sure?',
    message: null,
    loading: false,
    buttonClassName: 'primary',
    confirmButtonText: 'Confirm',
    cancelButtonText: "Cancel"
}
