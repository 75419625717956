import React from "react";
import Modal from "react-bootstrap/Modal";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import Select from "../../../pages/components/common/Select";
import Backend from "../../../utils/Backend";

const TYPE_STICKER_OPTIONS = [
  {
    label: "No",
    value: false,
  },
  {
    label: "Yes",
    value: true,
  },
];

const TYPE_BUSINESS_CARD_OPTIONS = [
  {
    label: "No",
    value: false,
  },
  {
    label: "Yes",
    value: true,
  },
];

const TYPE_POSTER_OPTIONS = [
  {
    label: "No",
    value: false,
  },
  {
    label: "Yes",
    value: true,
  },
];

export default class ShareQrModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _getState(props) {
    return {
      ...props,
      show: props.show,
      qrs: props.qrs,
      data: {
        sticker: false,
        business_card: false,
        poster: true,
        email: AuthManager.getCurrentUser().email,
        qr_ids: props.qrs?.map((qr) => qr.id).join(","),
      },
    };
  }

  _handleChange(e) {
    let data = this.state.data;
    data[e.target.name] = e.target.value;
    this.setState({ data });
  }

  _isFormValid() {
    let { data } = this.state;

    let { sticker, business_card, poster, email } = data;

    let error = null;

    if (!poster && !sticker && !business_card) {
      error = "Please select at least one size to print";
    } else if (!email || email === "") {
      error = "Please enter an email";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _onQrPrint() {
    let { data } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    this.setState({
      loading: true,
    });

    Backend.createPrintablePDF(data)
      .then(() => {
        Notify.success("A PDF will be sent to the above email shortly");
        this.setState({
          loading: false,
        });

        this.props.onHide();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    let { show, data, loading } = this.state;

    if (!show) {
      return null;
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName={"modal-dialog-centered modal-md"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Printable PDF</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/*
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Sticker</label>
            <div className="col-lg-9 my-auto">
              <Select
                value={TYPE_STICKER_OPTIONS.find(
                  (option) => option.value === data.sticker
                )}
                className="async-select-paginate"
                classNamePrefix="async-select-paginate"
                onChange={(option) => {
                  console.log("+++", option);
                  data.sticker = option.value;
                  this.setState({ data });
                }}
                options={TYPE_STICKER_OPTIONS}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Business Card</label>
            <div className="col-lg-9 my-auto">
              <Select
                value={TYPE_BUSINESS_CARD_OPTIONS.find(
                  (option) => option.value === data.business_card
                )}
                className="async-select-paginate"
                classNamePrefix="async-select-paginate"
                onChange={(option) => {
                  console.log("+++", option);
                  data.business_card = option.value;
                  this.setState({ data });
                }}
                options={TYPE_BUSINESS_CARD_OPTIONS}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Poster</label>
            <div className="col-lg-9 my-auto">
              <Select
                value={TYPE_POSTER_OPTIONS.find(
                  (option) => option.value === data.poster
                )}
                className="async-select-paginate"
                classNamePrefix="async-select-paginate"
                onChange={(option) => {
                  console.log("+++", option);
                  data.poster = option.value;
                  this.setState({ data });
                }}
                options={TYPE_POSTER_OPTIONS}
              />
            </div>
          </div>
          */}

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Email</label>
            <div className="col-lg-9 my-auto">
              <div className="input-group">
                <input
                  type="text"
                  name="email"
                  className="form-control form-control-solid"
                  value={data.email}
                  onChange={(e) => this._handleChange(e)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light-primary font-weight-bold"
            id="add-success"
            onClick={() => this.props.onHide()}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary font-weight-bold"
            id="add-success"
            disabled={loading}
            onClick={() => this._onQrPrint()}
          >
            Send PDF
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
