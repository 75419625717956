import React from "react"

export default class StatCard extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      title: props.title,
      subtitle: props.subtitle,
      type: props.type,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      title,
      subtitle,
      type,
    } = this.state

    return (
      <div className="col-lg-4" onClick={() => this.props.onClick()}>
        <div className="kt-widget17">
          <div className="kt-widget17__stats mx-0 w-100">
            <div className="kt-widget17__items">
              <div className="kt-widget17__item mx-0 my-0">
                <span className="kt-widget17__icon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className={`kt-svg-icon kt-svg-icon--${type}`}>
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z" fill="#000000" fillRule="nonzero"></path>
                      <path d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
                  </g>
                </svg>
                </span>
                <span className="kt-widget17__subtitle">
                  { title }
                </span>
                <span className="kt-widget17__desc">
                  { subtitle }
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

StatCard.defaultProps = {
  title: "Title",
  subtitle: "Subtitle",
  type: "brand"
}
