import React from 'react'

import Fees from "./components/Fees"

import GlobalFeesModal from "./components/modal/GlobalFeesModal"
import ConfirmModal from "./components/modal/ConfirmModal"

import Notify from '../utils/Notify'
import Backend from '../utils/Backend'
import Currency from '../utils/Currency'

const COUNTRY_SHORT_OPTIONS = [
  {
    label: "Ireland",
    value: "ie",
  },
  {
    label: "United Kingdom",
    value: "gb",
  }
]

export default class PaymentSettings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      countryShort: COUNTRY_SHORT_OPTIONS[0].value
    }
  }

  componentDidMount() {
    this._load(this.state.countryShort)
  }

  _load(countryShort){
    this.setState({ countryShort, isLoading: true })
    Backend.getGlobalFees(countryShort)
    .then(globalFees => {

      globalFees.sort((a, b) => {
        if (a.default === b.default) {
          return a.min_tip - b.min_tip;
        }
        return a.default ? -1 : 1
      })

      this.setState({ globalFees, fees: globalFees[0], isLoading: false })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ isLoading: false })
    })
  }

  _update(){
    let { fees } = this.state
    this.setState({ isUpdating: true })
    Backend.updateGlobalFees(fees)
    .then(fees => {
      this.setState({ fees, isUpdating: false })
      Notify.success("Successfully Updated")
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ fees, isUpdating: false })
    })
  }

  _deleteFees(fees){
    this.setState({ isUpdating: true })
    Backend.deleteGlobalFees(fees)
    .then(fees => {
      this.setState({
        fees: null,
        isUpdating: false,
        showConfirmDeleteModal: false
      }, () => this._load(this.state.countryShort))
      Notify.success("Successfully Deleted")
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ fees, isUpdating: false })
    })
  }

  render() {
    let {
      fees,
      isUpdating,
      isLoading,
      countryShort,
      globalFees,
      showGlobalFeesModal,
      showConfirmDeleteModal
    } = this.state

    let currencyCode = fees?.country_short.toLowerCase() === "ie" ? "eur" : "gbp"

    if(isLoading){
      return null
    }

    return (
      <>
        <div className="card card-custom gutter-b">
          <div className="card-header flex-wrap border-0 pt-6 pb-0">
            <div className="card-title row w-100">
              <div className="col-md-6">
                <h3 className="card-label">
                  Global Fees<small> These fees are overwritten by individuals settings</small>
                </h3>
              </div>
              { !isLoading &&
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-4">
                      <select
                        className="form-control"
                        onChange={(e) => {
                          this._load(e.target.value);
                        }}
                        value={countryShort}
                      >
                        {COUNTRY_SHORT_OPTIONS.map(option => {
                          return (
                            <option value={option.value}>
                              {option.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <select
                        className="form-control"
                        onChange={(e) => {
                          fees = globalFees.find(f => f.id == e.target.value)
                          this.setState({ fees });
                        }}
                        value={fees?.id}
                      >
                      {
                        globalFees.map(fees => {
                          let range = `${Currency.format(fees.min_tip, currencyCode)} - ${Currency.format(fees.max_tip, currencyCode)} `
                          return (
                            <option value={fees.id}>
                              {fees.default ? "Default" : range}
                            </option>
                          );
                        })
                      }
                      </select>
                    </div>
                    <div className="col-md-4">
                      <button
                        type="button"
                        className="btn btn-primary generate-btn"
                        disabled={isUpdating}
                        onClick={() => this.setState({ showGlobalFeesModal: true })}
                      >
                        <i className="flaticon2-plus"></i>
                        New Global Fees
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="card-body">
            <Fees key={fees?.id} fees={fees} onUpdated={fees => this.setState({ fees })}/>
          </div>
        </div>
        { fees?.default &&
          <div className="card card-custom">
            <div className="card-header flex-wrap border-0 pt-6 pb-0">
              <div className="card-title">
                <h3 className="card-label">Withdrawal Settings
                </h3></div>
            </div>
            <div className="card-body">
              <form className="form" id="globalFeesForm">
                {/*begin: Role Form*/}
                {/*begin::Input*/}
                  <div className="form-group row">
                    <label className="col-3 col-form-label">Minimum amount withdrawable</label>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={fees.platform_minimum_payout_amount / 100}
                        onChange={e => {
                          let amount = Math.round(parseFloat(e.target.value).toFixed(2) * 100)
                          fees.platform_minimum_payout_amount = amount || 0
                          this.setState({ fees })
                        }}
                      />
                      <span className="form-text text-muted">Leave blank for no limit.</span>
                    </div>
                  </div>
              </form>
            </div>
          </div>
        }
        <div className="mt-7 d-flex justify-content-between">

          { (fees && !fees.default) &&
            <a
              href="javascript:;"
              className="btn btn-outline-danger font-weight-bold ml-auto mr-3"
              disabled={isUpdating}
              onClick={() => this.setState({ showConfirmDeleteModal: true })}
            >
              Delete
            </a>
          }
          <a
            href="javascript:;"
            className={`btn btn-primary font-weight-bold ${fees?.default ? "ml-auto" : ""}`}
            disabled={isUpdating}
            onClick={() => this._update()}
          >
            Save Changes
          </a>
        </div>
        <GlobalFeesModal
          show={showGlobalFeesModal}
          countryShort={countryShort}
          onHide={() => {
            this.setState({ showGlobalFeesModal: false })
          }}
          onCreated={fees => {
            this.setState({
              showGlobalFeesModal: false,
              countryShort: fees.country_short,
            })
            this._load(fees.country_short)
          }}
        />
        <ConfirmModal
          show={showConfirmDeleteModal}
          loading={isUpdating}
          message={"You cannot undo this action"}
          cancelButtonText="No, Cancel"
          confirmButtonText={"Yes, Delete"}
          onCancel={() =>
            this.setState({ showConfirmDeleteModal: false })
          }
          onConfirm={() => this._deleteFees(fees)}
        />
      </>
    )
  }
}
