import React from 'react'

import AuthManager from '../utils/AuthManager'
import Email from '../utils/Email'
import Notify from '../utils/Notify'
import Backend from '../utils/Backend'
import General from '../utils/General'

import Div from './components/layouts/Div'

const MODE_SIGNUP = 'signup'
const MODE_SIGNIN = 'signin'
const MODE_FORGOT_PASSWORD = 'forgot_password'
const MODE_RESET_PASSWORD = 'reset_password'

export default class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      isLoading: false,
      signUpStep: 1,
      mode: MODE_SIGNIN,
    }
  }

  _isFormValid() {
    let { email, password, confirmPassword, mode, code } = this.state

    if (!Email.isValid(email)) {
      this.setState({ error: 'Please enter a valid email address' })
      return false
    }

    if (mode == MODE_RESET_PASSWORD) {
      if (!code) {
        this.setState({ error: 'Please enter a valid code' })
        return false
      }
      if (password.length < 6) {
        this.setState({ error: 'Password must be at least 6 characters' })
        return false
      }
      if (password != confirmPassword) {
        this.setState({ error: 'Passwords do not match' })
        return false
      }
    }

    return true
  }

  _handleLogInClicked(e) {
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password } = this.state

    this.setState({ isLoading: true })
    AuthManager.login(email, password, false)
      .then(() => {
        // if (AuthManager.currentWebsite) {
        //   this.setState({ isLoading: false })
        //   this.props.history.push('/')
        // }
        this.setState({ isLoading: false })
        this.props.history.push('/')

        // this.setState(
        //   {
        //     mode: MODE_SIGNUP,
        //     signUpStep: 2,
        //     company_name: AuthManager.currentUser.company.name,
        //   },
        //   () => {
        //     this.setState({
        //       isLoading: false,
        //     })
        //   }
        // )
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        })
      })
  }

  _handleRequestResetPassword(e) {
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password, confirmPassword } = this.state

    this.setState({ isLoading: true })
    AuthManager.requestResetPassword(email)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_RESET_PASSWORD,
          password: '',
          confirmPassword: '',
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        })
      })
  }

  _handleResetPassword(e) {
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password, code } = this.state

    this.setState({ isLoading: true })
    AuthManager.resetPassword(email, password, code)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_SIGNIN,
          email: '',
          password: '',
          confirmPassword: '',
        })
        Notify.success('Your password has been reset')
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        })
      })
  }

  _isSignUpFormValid() {
    let {
      first_name,
      last_name,
      email,
      phone_number,
      agree,
      password,
      company_name,
    } = this.state

    let error = null
    if (first_name == null || first_name.length == 0) {
      error = 'Please enter a valid first name'
    } else if (last_name == null || last_name.length == 0) {
      error = 'Please enter a valid last name'
    } else if (company_name == null || company_name.length == 0) {
      error = 'Please enter a valid company name'
    } else if (email == null || email.length == 0) {
      error = 'Please enter a valid email'
    } else if (phone_number == null || phone_number.length < 6) {
      error = 'Please enter a valid phone number'
    } else if (password == null || password.length == 0) {
      error = 'Please enter a valid password'
    } else if (agree == null || !agree) {
      error = 'You must agree to the terms and conditions'
    }

    if (error) {
      Notify.error(error)
      return false
    }

    return true
  }

  _signUpPressed(e) {
    e.preventDefault()

    if (!this._isSignUpFormValid()) {
      return
    }

    let {
      first_name,
      last_name,
      company_name,
      email,
      phone_number,
      password,
    } = this.state

    this.setState({ isLoading: true })

    let data = {
      first_name,
      last_name,
      company_name,
      email,
      phone_number,
      password,
    }

    AuthManager.register(data)
      .then(() => {
        this.setState({
          mode: MODE_SIGNUP,
          signUpStep: 2,
          isLoading: false,
        })
        window.scrollTo(0, 0)
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        })
        Notify.error(error.message)
      })
  }

  _renderError() {
    let { error } = this.state

    if (!error) {
      return null
    }

    return (
      <div
        className="kt-alert kt-alert--outline alert alert-danger alert-dismissible"
        role="alert"
      >
        <span>{error}</span>
      </div>
    )
  }

  _renderSignUpStep() {
    let { mode, signUpStep, slug, errorSlug, isLoading } = this.state

    return (
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        novalidate="novalidate"
      >
        <div className="pb-13 pt-lg-0 pt- d-none d-md-block">
          <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
            Sign Up
          </h3>
          <p className="text-muted font-weight-bold font-size-h4">
            Enter your details to create your account
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group fv-plugins-icon-container">
              <input
                className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                type="text"
                placeholder="First Name"
                name="first_name"
                autocomplete="off"
                onChange={(e) => this.setState({ first_name: e.target.value })}
                autoFocus
              />
              <div className="fv-plugins-message-container"></div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group fv-plugins-icon-container">
              <input
                className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                type="text"
                placeholder="Last Name"
                name="last_name"
                autocomplete="off"
                onChange={(e) => this.setState({ last_name: e.target.value })}
              />
              <div className="fv-plugins-message-container"></div>
            </div>
          </div>
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
            type="text"
            placeholder="Company Name"
            name="company_name"
            autocomplete="off"
            onChange={(e) => this.setState({ company_name: e.target.value })}
          />
          <div className="fv-plugins-message-container"></div>
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
            type="text"
            placeholder="Company Address"
            name="company_address"
            autocomplete="off"
            onChange={(e) => this.setState({ company_address: e.target.value })}
          />
          <div className="fv-plugins-message-container"></div>
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
            type="text"
            placeholder="Email"
            name="email"
            autocomplete="off"
            onChange={(e) => this.setState({ email: e.target.value })}
          />
          <div className="fv-plugins-message-container"></div>
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
            type="password"
            placeholder="Password"
            name="password"
            autocomplete="off"
            onChange={(e) => this.setState({ password: e.target.value })}
          />
          <div className="fv-plugins-message-container"></div>
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
            type="password"
            placeholder="Password"
            name="password"
            autocomplete="off"
            onChange={(e) =>
              this.setState({ confirm_password: e.target.value })
            }
          />
          <div className="fv-plugins-message-container"></div>
        </div>

        <div
          className="form-group"
          onClick={(e) => this.setState({ agree: e.target.checked })}
        >
          <label className="checkbox mb-0">
            <input type="checkbox" name="agree" />I agree with the
            <a className="ml-1" href="#">
              terms and conditions
            </a>
            .<span></span>
          </label>
        </div>

        <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
          <button
            type="button"
            className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
          >
            Create Account
          </button>
          <button
            type="button"
            onClick={() => this.setState({ mode: MODE_SIGNIN })}
            className="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  render() {
    let {
      email,
      password,
      confirmPassword,
      isLoading,
      mode,
      signUpStep,
      code,
      showImagePicker,
    } = this.state

    return (
      <div className="metr_v702 d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/* begin : Login Aside */}
          <div
            className="login-aside d-flex flex-column flex-row-auto"
          >
            {/*begin::Aside Top*/}
            <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
              {/*begin::Aside header*/}
              <a href="#" className="text-center mb-10">
                <svg 
                  width="118" 
                  height="56" 
                  viewBox="0 0 946 263"
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path 
                    d="M243.31 259L154.51 125.06L237.39 -1.7643e-05H173.38L122.32 76.96L71.26 -1.7643e-05H5.77L89.76 125.8L0.96 259H65.34L121.58 174.27L178.19 259H243.31ZM420.936 160.21C420.936 111.74 382.456 73.63 333.246 73.63C280.336 73.63 238.896 114.33 238.896 167.24C238.896 221.26 281.076 262.7 334.356 262.7C372.096 262.7 402.436 248.64 415.756 224.59L375.056 199.8C368.766 210.9 351.746 218.3 335.466 218.3C310.676 218.3 293.656 205.35 288.846 180.56H419.456C420.566 173.53 420.936 166.5 420.936 160.21ZM289.586 147.26C294.396 127.65 308.826 115.81 330.656 115.81C349.896 115.81 366.546 129.13 368.766 147.26H289.586ZM502.264 -1.7643e-05H450.094V259H502.264V-1.7643e-05ZM671.845 -1.7643e-05V103.23C659.265 84.36 639.655 73.63 614.125 73.63C567.135 73.63 529.765 115.81 529.765 167.98C529.765 220.15 567.135 262.7 614.125 262.7C639.655 262.7 659.265 251.97 671.845 233.1V259H724.015V-1.7643e-05H671.845ZM626.335 212.75C601.175 212.75 580.825 193.14 580.825 167.98C580.825 143.19 601.175 123.21 626.335 123.21C651.865 123.21 671.845 142.82 671.845 167.98C671.845 193.51 651.865 212.75 626.335 212.75ZM892.978 77.33V103.23C880.398 84.36 860.788 73.63 835.258 73.63C788.268 73.63 750.898 115.81 750.898 167.98C750.898 220.15 788.268 262.7 835.258 262.7C860.788 262.7 880.398 251.97 892.978 233.1V259H945.148V77.33H892.978ZM847.468 212.75C822.308 212.75 801.958 193.14 801.958 167.98C801.958 143.19 822.308 123.21 847.468 123.21C872.998 123.21 892.978 142.82 892.978 167.98C892.978 193.51 872.998 212.75 847.468 212.75Z" 
                    fill="white"
                  />
                </svg>
              </a>
              {/*end::Aside header*/}
              {/*begin::Aside title*/}
              <h3
                className="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
                style={{ color: '#fff' }}
              >
                Split tips
                <br />
                for all of your staff
              </h3>
              {/*end::Aside title*/}
            </div>
            {/*end::Aside Top*/}
            {/*begin::Aside Bottom*/}
            <div
              className="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
              style={{
                backgroundSize: 'contain',
                backgroundImage:
                  'url(' +
                  process.env.PUBLIC_URL +
                  '/assets/media/illustrations/tip.png)',
              }}
            />
            {/*end::Aside Bottom*/}
          </div>
          {/* end : Login Aside */}

          {/* <!--begin::Content--> */}
          <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 pt-38 pb-38 mx-auto  w-100">
            <div className="d-flex flex-column-fluid flex-center">
              {/* <!--begin::Signin--> */}
              <div
                className="login-form login-signin"
                style={{
                  display: mode == MODE_SIGNIN ? 'block' : 'none',
                }}
              >
                <form className="form" novalidate="novalidate">
                  <div className="pb-13 pt-lg-0 pt-5">
                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                      Welcome To Xelda
                    </h3>
                  </div>

                  {this._renderError()}

                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">
                      Email
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="text"
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      value={email}
                      onChange={(e) =>
                        this.setState({ email: e.target.value, error: null })
                      }
                      autoFocus
                    />
                  </div>

                  <div className="form-group">
                    <div className="d-flex justify-content-between mt-n5">
                      <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                        Password
                      </label>
                      <a
                        href="javascript:;"
                        className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                        onClick={() =>
                          this.setState({
                            email: '',
                            mode: MODE_FORGOT_PASSWORD,
                            error: null,
                          })
                        }
                      >
                        Forgot Password ?
                      </a>
                    </div>
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value, error: null })
                      }
                    />
                  </div>

                  <div class="pb-lg-0 pb-5">
                    <button
                      type="button"
                      className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                      onClick={(e) => this._handleLogInClicked(e)}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Loading... ' : 'Sign In'}
                    </button>
                  </div>
                </form>
              </div>
              {/* <!--end::Signin-->

                {/* <!--begin::Signup--> */}
              <div
                className="login-form login-signup"
                style={{
                  display: mode == MODE_SIGNUP ? 'block' : 'none',
                }}
              >
                {this._renderSignUpStep()}
              </div>
              {/* <!--end::Signup-->

                <!--begin::Forgot--> */}
              <div
                className="login-form login-forgot"
                style={{
                  display: mode == MODE_FORGOT_PASSWORD ? 'block' : 'none',
                }}
              >
                <form
                  className="form"
                  novalidate="novalidate"
                  id="kt_login_forgot_form"
                >
                  <div className="pb-13 pt-lg-0 pt-5">
                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                      Forgotten Password ?
                    </h3>
                    <p className="text-muted font-weight-bold font-size-h4">
                      Enter your email to reset your password
                    </p>
                  </div>

                  {this._renderError()}

                  <div className="form-group">
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                      type="email"
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      value={email}
                      onChange={(e) =>
                        this.setState({ email: e.target.value, error: null })
                      }
                    />
                  </div>

                  <div className="form-group d-flex flex-wrap pb-lg-0 pull-left">
                    <button
                      className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                      onClick={(e) => this._handleRequestResetPassword(e)}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Loading... ' : 'Request'}
                    </button>

                    <button
                      className="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                      onClick={(e) => {
                        e.preventDefault()
                        this.setState({
                          email: '',
                          password: '',
                          mode: MODE_SIGNIN,
                          error: null,
                        })
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
              {/* <!--end::Forgot--> */}

              {/* <!--begin::reset--> */}
              <div
                className="login-form login-reset"
                style={{
                  display: mode == MODE_RESET_PASSWORD ? 'block' : 'none',
                }}
              >
                <form className="form" novalidate="novalidate">
                  <div className="pb-13 pt-lg-0 pt-5">
                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                      Reset Password
                    </h3>
                    <p className="text-muted font-weight-bold font-size-h4">
                      A verification code was sent to your email, please enter
                      it below along with your new password
                    </p>
                  </div>

                  {this._renderError()}

                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">
                      Code
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="text"
                      placeholder="Code"
                      name="code"
                      autoComplete="off"
                      value={code}
                      onChange={(e) =>
                        this.setState({ code: e.target.value, error: null })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">
                      Password
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="password"
                      placeholder="Password"
                      name="password"
                      autoComplete="off"
                      value={password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value, error: null })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">
                      Confirm Password
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="password"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      autoComplete="off"
                      value={confirmPassword}
                      onChange={(e) =>
                        this.setState({
                          confirmPassword: e.target.value,
                          error: null,
                        })
                      }
                    />
                  </div>

                  <div className="form-group d-flex flex-wrap pb-lg-0">
                    <button
                      className="btn btn-outline-dark font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                      onClick={(e) => {
                        e.preventDefault()
                        this.setState({
                          email: '',
                          password: '',
                          mode: MODE_SIGNIN,
                          error: null,
                        })
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                      onClick={(e) => this._handleResetPassword(e)}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Loading... ' : 'Update Password'}
                    </button>
                  </div>
                </form>
              </div>
              {/* <!--end::reset--> */}
            </div>
            {/* <!--end::Content body--> */}
          </div>
          {/* end::Content */}
        </div>
      </div>
    )
  }
}
