import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Fees from "../Fees"

import Backend from "../../../utils/Backend"
import General from "../../../utils/General"
import Notify from "../../../utils/Notify"

const KEYS = [
  "platform_transaction_tiper_flat",
  "platform_transaction_tiper_percentage",
  "platform_transaction_tipee_flat",
  "platform_transaction_tipee_percentage",
]

export default class GlobalFeesModal extends React.Component {
  constructor(props){
    super(props)

    this.state = this._getState(props)
  }

  componentWillReceiveProps(props){
    this.setState(this._getState(props))
  }

  _getState(props){
    return {
      show: props.show,
      qr: props.qr,
      fees: {
        group: props.qr.group
      }
    }
  }

  _isFormValid(){
    let {
      fees,
    } = this.state

    let error = null
    if(fees.min_tip == null || fees.min_tip == undefined){
      error = "You must define a 'From' amount"
    }
    else if(fees.max_tip == null || fees.max_tip == undefined){
      error = "You must define a 'To' amount"
    }
    else{
      for(var i=0; i < KEYS.length; i++){
        let key = KEYS[i]
        if(fees.[key] == null || fees[key] == undefined){
          error = "You must define all fees"
          break
        }
      }
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _save(){
    let {
      fees,
    } = this.state

    if(!this._isFormValid()){
      return false
    }

    this.setState({ isLoading: true })
    Backend.createGroupFees(fees)
    .then(fees => {
      this.setState({ fees, isLoading: false })
      this.props.onCreated(fees)
      Notify.success("Fees Created!")
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ isLoading: false })
    })
  }

  render() {
    let {
      show,
      fees,
      isLoading,
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => {
          if(!isLoading){
            this.props.onHide()
          }
        }}
        className="sub-modal"
        dialogClassName="modal-50vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>New Fees</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Fees
            fees={fees}
            onUpdated={fess => this.setState({ fees })}
            hint=""
          />
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            disabled={isLoading}
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary font-weight-bold"
            disabled={isLoading}
            onClick={() => this._save()}
          >
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <path d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z" fill="#000000" fill-rule="nonzero" transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "></path>
                </g>
              </svg>
            </span>
            Save Changes
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
