import React from 'react'

import Fees from "./components/Fees"

import GlobalFeesModal from "./components/modal/GlobalFeesModal"
import ConfirmModal from "./components/modal/ConfirmModal"

import Notify from '../utils/Notify'
import Backend from '../utils/Backend'
import Currency from '../utils/Currency'


export default class PaymentSettings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
    }
  }

  componentDidMount() {
    this._load()
  }

  _load(){
    Backend.getPoolDescriptions()
    .then(descriptions => {
      this.setState({ descriptions, isLoading: false })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ isLoading: false })
    })
  }

  _update(description){
    this.setState({ isUpdating: true })
    Backend.updatePoolDescription(description)
    .then(() => {
      this.setState({ isUpdating: false })
      Notify.success("Successfully Updated")
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ isUpdating: false })
    })
  }



  render() {
    let {
      isLoading,
      descriptions,
      isUpdating
    } = this.state


    if(isLoading){
      return null
    }

    return (
      <div className="row">
        { descriptions.map((description, index) => {
            let title = description.key == "description_pool_n_solo" ? "Pool N Solo" : "Pool N Split"
            return (
              <div key={description.key} className="col-6">
                <div className="card card-custom gutter-b">
                  <div className="card-header flex-wrap border-0 pt-6 pb-0">
                    <div className="card-title row w-100">
                      <div className="col-md-6">
                        <h3 className="card-label">
                          { title }
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <textarea
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                        type="text"
                        placeholder="Tootltip"
                        name="description_pool_n_split"
                        autoComplete="off"
                        value={description.value}
                        onChange={(e) =>{
                          descriptions[index].value = e.target.value || null
                          this.setState({ descriptions })
                        }}
                      />
                    </div>
                  </div>
                  <div className="card-footer">
                      <div className="mr-4">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm print-btn"
                          onClick={() =>this._update(description)}
                          disabled={isUpdating}
                        >
                          Save
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            )
          })
        }


      </div>
    )
  }
}
