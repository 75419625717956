import React from 'react'

export default class MobileHeader extends React.Component {
  render() {
    return (
      <div
        id="kt_header_mobile"
        className="kt-header-mobile  kt-header-mobile--fixed "
      >
        <div className="kt-header-mobile__logo">
          <a href="/dealer">
            <svg 
              width="63" 
              height="30" 
              viewBox="0 0 946 263"
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                d="M243.31 259L154.51 125.06L237.39 -1.7643e-05H173.38L122.32 76.96L71.26 -1.7643e-05H5.77L89.76 125.8L0.96 259H65.34L121.58 174.27L178.19 259H243.31ZM420.936 160.21C420.936 111.74 382.456 73.63 333.246 73.63C280.336 73.63 238.896 114.33 238.896 167.24C238.896 221.26 281.076 262.7 334.356 262.7C372.096 262.7 402.436 248.64 415.756 224.59L375.056 199.8C368.766 210.9 351.746 218.3 335.466 218.3C310.676 218.3 293.656 205.35 288.846 180.56H419.456C420.566 173.53 420.936 166.5 420.936 160.21ZM289.586 147.26C294.396 127.65 308.826 115.81 330.656 115.81C349.896 115.81 366.546 129.13 368.766 147.26H289.586ZM502.264 -1.7643e-05H450.094V259H502.264V-1.7643e-05ZM671.845 -1.7643e-05V103.23C659.265 84.36 639.655 73.63 614.125 73.63C567.135 73.63 529.765 115.81 529.765 167.98C529.765 220.15 567.135 262.7 614.125 262.7C639.655 262.7 659.265 251.97 671.845 233.1V259H724.015V-1.7643e-05H671.845ZM626.335 212.75C601.175 212.75 580.825 193.14 580.825 167.98C580.825 143.19 601.175 123.21 626.335 123.21C651.865 123.21 671.845 142.82 671.845 167.98C671.845 193.51 651.865 212.75 626.335 212.75ZM892.978 77.33V103.23C880.398 84.36 860.788 73.63 835.258 73.63C788.268 73.63 750.898 115.81 750.898 167.98C750.898 220.15 788.268 262.7 835.258 262.7C860.788 262.7 880.398 251.97 892.978 233.1V259H945.148V77.33H892.978ZM847.468 212.75C822.308 212.75 801.958 193.14 801.958 167.98C801.958 143.19 822.308 123.21 847.468 123.21C872.998 123.21 892.978 142.82 892.978 167.98C892.978 193.51 872.998 212.75 847.468 212.75Z" 
                fill="#9600FF"
              />
            </svg>
          </a>
        </div>
        <div className="kt-header-mobile__toolbar">
          <button
            className="kt-header-mobile__toggler kt-header-mobile__toggler--left"
            id="kt_aside_mobile_toggler"
          >
            <span></span>
          </button>
          <button
            className="kt-header-mobile__topbar-toggler"
            id="kt_header_mobile_topbar_toggler"
          >
            <i className="flaticon-more"></i>
          </button>
        </div>
      </div>
    )
  }
}
