import React from "react"

import AuthManager from "../../utils/AuthManager"

import moment from 'moment'

export default class Footer extends React.Component {
  constructor(props){
    super(props)
    this.state = {}
  }

  render() {
    let text = "©" + moment().year() + " Xelda"
    return (
      <div className="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop">
        <div class="kt-container  kt-container--fluid ">
          <div class="kt-footer__copyright">
            { text }
          </div>
        </div>
			</div>
    )
  }
}
