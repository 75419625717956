import React from "react";

import moment from "moment";

import { withRouter } from "react-router-dom";

import print from "print-js";

import BaseTable from "./BaseTable";

import QrModal from "../modal/QrModal";
import ConfirmModal from "../modal/ConfirmModal";
import ShareQrModal from "../modal/ShareQrModal";
import PrintQrCodesModal from "../modal/PrintQrCodesModal";

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Currency from "../../../utils/Currency";

import Notify from "../../../utils/Notify";

class QrCodesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      client: props.client,
      selectedQrs: [],
    };

    this.table = React.createRef();
    console.log("table", this.table);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _print(qr) {
    print(qr.image.original, "image");
  }

  _getConfirDeleteMessage(qr) {
    if (!qr) {
      return null;
    }
    if (qr.deleted_at) {
      return "";
    }
    return "Unpausing a users account will grant access to Xelda";
  }

  _getConfirmPauseButtonText(client) {
    if (!client) {
      return null;
    }
    if (client.user.status === "active") {
      return "Yes, pause";
    }
    return "Yes, unpause";
  }

  _handleSearch = General.debounce((searchTerm) => {
    this.setState({ searchTerm }, () => this.table.current.refresh());
  }, 500);

  _createQr() {
    this.setState({ isUpdating: true });
    Backend.createQr()
      .then((qr) => {
        console.log(qr);
        this.setState({
          selectedQr: qr,
          showQrModal: true,
        });
        this.table.current.refresh();
        this.setState({ isUpdating: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isUpdating: false });
      });
  }

  _deleteQr(qr) {
    this._updateQr(qr, "Qr Code Removed!", true);
  }

  _restoreQr(qr) {
    this._updateQr(qr, "Qr Code Restored!", false);
  }

  _updateQr(qr, successMessage, deleteQr = false) {
    this.setState({ isUpdating: true });
    return this._handleBackend(qr, deleteQr)
      .then(() => {
        this.table.current.refresh();
        this.setState({
          isUpdating: false,
          showConfirmDeleteModal: false,
          showConfirmRestoreModal: false,
        });
        Notify.success(successMessage);
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isUpdating: false });
      });
  }

  _handleBackend(qr, deleteQr = false) {
    if (deleteQr) {
      return Backend.deleteQr(qr);
    }
    return Backend.restoreQr(qr);
  }

  _getColumns() {
    return [
      {
        Header: "",
        accessor: "id",
        width: 50,
        Cell: (rowInfo) => {
          let { selectedQrs } = this.state;

          let qr = rowInfo.original;
          let selectedIndex = selectedQrs.findIndex(
            (selectedQr) => selectedQr.id === qr.id
          );
          return (
            <div className="font-size-sm text-dark-50 text-hover-primary">
              <input
                type="checkbox"
                checked={selectedIndex > -1}
                onChange={(e) => {
                  if (e.target.checked) {
                    selectedQrs.push(qr);
                  } else {
                    if (selectedIndex > -1) {
                      selectedQrs.splice(selectedIndex, 1);
                    }
                  }
                  this.setState({
                    selectedQrs,
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "ID",
        id: "id",
        accessor: (item) => item.slug,
        width: 220,
      },

      {
        Header: "URL",
        accessor: "url",
        width: 400,
        Cell: (rowInfo) => {
          let qr = rowInfo.original;
          return (
            <div className="font-size-sm text-dark-50 text-hover-primary">
              <a href={qr.url} target="_blank">
                {qr.url}
              </a>
            </div>
          );
        },
      },
      {
        Header: "REGISTERED TO",
        id: "client.user.first_name",
        width: 180,
        Cell: (rowInfo) => {
          let qr = rowInfo.original;
          let client = qr.client;
          if (!client) {
            return "-";
          }
          let name = `${client.user.first_name} ${client.user.last_name}`;

          return (
            <>
              <span className="text-dark-75 font-weight-bold line-height-sm">
                {name}
              </span>
              <div className="font-size-sm text-dark-50 text-hover-primary">
                {client.user.email}
              </div>
            </>
          );
        },
      },
      {
        Header: "REVERSE FEES ALLOWED",
        id: "reverse_fees_enabled",
        width: 180,
        Cell: (rowInfo) => {
          let qr = rowInfo.original;

          if(qr.type === "other"){
            return "N/A"
          }
          return (
            <div className="form-group row mb-0 align-items-center c-toggle">
              <div className="col-10 text-left mx-height-px-34">
               <span className="switch mt-0">
                <label className="mb-0">
                 <input
                  key={qr.reverse_fees_enabled}
                  type="checkbox"
                  defaultChecked={qr.reverse_fees_enabled}
                  onChange={e => {
                    qr.reverse_fees_enabled = e.target.checked
                    Backend.updateQr(qr)
                    .then(qr => {
                      Notify.success("Updated QR Code")
                    })
                    .catch(error => {
                      Notify.error(error.message)
                      qr.reverse_fees_enabled = !e.target.checked
                      this.setState({ ...this.state, update: !this.state.update })
                    })
                  }}
                 />
                 <span/>
                </label>
               </span>
              </div>
              <label className="col-2 col-form-label text-left px-7"> </label>
            </div>
          );
        },
      },
      {
        Header: "ADDED",
        id: "created_at",
        width: 90,
        Cell: (rowInfo) =>
          moment(rowInfo.original.created_at).format("DD MMM YYYY"),
      },
      {
        Header: "ACTIONS",
        id: "status",
        sortable: false,
        Cell: (rowInfo) => {
          let qr = rowInfo.original;

          return (
            <>
              <a
                href="javascript:;"
                className="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                onClick={() =>
                  this.setState({
                    selectedQrs: [qr],
                    showPrintQrModal: true,
                  })
                }
              >
                <i
                  className="fas fa-qrcode text-primary icon-sm"
                  data-container="table"
                  data-toggle="popover"
                  data-placement="top"
                  data-content="Print QR Code"
                  data-original-title=""
                  title=""
                ></i>
              </a>
              <a
                href="javascript:;"
                className="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                onClick={() =>
                  this.setState({ selectedQr: qr, showShareQrModal: true })
                }
              >
                <i
                  className="fas fa-share text-primary icon-sm"
                  data-container="table"
                  data-toggle="popover"
                  data-placement="top"
                  data-content="Share QR Code"
                  data-original-title=""
                  title=""
                ></i>
              </a>
              {!qr.deleted_at && (
                <a
                  href="javascript:;"
                  className="btn btn-icon btn-light btn-hover-primary btn-sm"
                  onClick={() =>
                    this.setState({
                      selectedQr: qr,
                      showConfirmDeleteModal: true,
                    })
                  }
                >
                  <i
                    className="flaticon2-cross text-primary icon-sm"
                    data-container="table"
                    data-toggle="popover"
                    data-placement="top"
                    data-content="Remove"
                    data-original-title=""
                    title=""
                  ></i>
                </a>
              )}
              {qr.deleted_at && (
                <a
                  href="javascript:;"
                  className="btn btn-icon btn-light btn-hover-primary btn-sm"
                  onClick={() =>
                    this.setState({
                      selectedQr: qr,
                      showConfirmRestoreModal: true,
                    })
                  }
                >
                  <i
                    className="flaticon2-check-mark text-primary icon-sm"
                    data-container="table"
                    data-toggle="popover"
                    data-placement="top"
                    data-content="Restore"
                    data-original-title=""
                    title=""
                  ></i>
                </a>
              )}
            </>
          );
        },
      },
    ];
  }

  render() {
    let {
      client,
      loading,
      isUpdating,
      selectedQr,
      searchTerm,
      selectedQrs,
      showQrModal,
      showPrintQrModal,
      showShareQrModal,
      showConfirmDeleteModal,
      showConfirmRestoreModal,
      print,
    } = this.state;

    const columns = this._getColumns();

    let endpoint = window.Api.QrCodes;
    if (client) {
      endpoint += `?client_id=${client.user.id}`;
    }

    return (
      <div>
        <BaseTable
          ref={this.table}
          title={this.props.title}
          endpoint={endpoint}
          noDataMessage={"No qr codes found"}
          title={this.props.title}
          searchTerm={searchTerm}
          columns={columns}
          exportButtonsEnabled={false}
          showObjectFilter={false}
          showPaginationTop={this.props.showPaginationTop}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          showHeader={client == null}
          showSearch={client == null}
          renderToolbar={(rowInfo) => {
            if (client) {
              return null;
            }
            return (
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">
                  &nbsp;
                  <div className="row align-items-right">
                    <div className="col-md-6 my-2 my-md-0">
                      <button
                        type="button"
                        className="btn btn-primary generate-btn"
                        disabled={isUpdating}
                        onClick={() => this._createQr()}
                      >
                        <i className="flaticon2-plus"></i>
                        Generate New QR
                      </button>
                    </div>
                    <div className="col-md-6 my-2 my-md-0">
                      {selectedQrs.length > 0 && (
                        <button
                          type="button"
                          className="btn btn-secondary print-btn"
                          onClick={() =>
                            this.setState({
                              showPrintQrModal: true,
                            })
                          }
                        >
                          Print
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "ACTIONS" ? "visible" : "hidden",
              },
            };
          }}
        />

        <ConfirmModal
          show={showConfirmDeleteModal}
          loading={isUpdating}
          message={"Removing a Qr Code will revoke access to it on Xelda"}
          cancelButtonText="No, Cancel"
          confirmButtonText={"Yes, Remove"}
          onCancel={() =>
            this.setState({ selectedQr: null, showConfirmDeleteModal: false })
          }
          onConfirm={() => this._deleteQr(selectedQr)}
        />
        <ConfirmModal
          show={showConfirmRestoreModal}
          loading={isUpdating}
          message={"Restoring a Qr Code will allow access to it on Xelda"}
          cancelButtonText="No, Cancel"
          confirmButtonText={"Yes, Restore"}
          onCancel={() =>
            this.setState({ selectedQr: null, showConfirmRestoreModal: false })
          }
          onConfirm={() => this._restoreQr(selectedQr)}
        />
        <QrModal
          show={showQrModal}
          qr={selectedQr}
          onPrint={() => {
            this._print(selectedQr);
            this.setState({ selectedQr: null, showQrModal: false });
          }}
          onShare={() =>
            this.setState({ showQrModal: false, showShareQrModal: true })
          }
          onHide={() => this.setState({ selectedQr: null, showQrModal: false })}
        />
        <ShareQrModal
          show={showShareQrModal}
          qr={selectedQr}
          onHide={() =>
            this.setState({ selectedQr: null, showShareQrModal: false })
          }
        />
        <PrintQrCodesModal
          show={showPrintQrModal}
          qrs={selectedQrs}
          onHide={() =>
            this.setState({ selectedQrs: [], showPrintQrModal: false })
          }
        />
      </div>
    );
  }
}

QrCodesTable.defaultProps = {
  title: "All QR Codes",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(QrCodesTable);
