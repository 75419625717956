import React from 'react'

import Notice from './components/Notice'
import Progress from './components/Progress'
import Address from './components/Address'

import PrintQrCodesModal from "./components/modal/PrintQrCodesModal";

import Notify from '../utils/Notify'
import Backend from '../utils/Backend'

export default class Order extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true
    }
  }

  componentDidMount(){
    let orderId = this.props.match.params.orderId
    this._load(orderId)
  }

  _load(orderId){
    this.setState({ isLoading: true })
    Backend.getOrder(orderId)
    .then(order => {
      this.setState({ order, isLoading: false, qr: order.items[0].qr })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ isLoading: false })
    })
  }

  _updateStatus(status){
    let { order } = this.state
    order.status = status

    this.setState({ isUpdating: true })
    Backend.updateOrder(order)
    .then(order => {
      this.setState({ order, isUpdating: false })
      Notify.success("Order Status Updated")
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ isUpdating: false })
    })
  }

  render() {
    let { order, isUpdating, showPrintQrModal, qr } = this.state
    if(!order){
      return null
    }
    let user = order.client.user
    let email = user.email
    let phoneCountryCode = user.phone_country_code
    let phoneNumber = user.phone_number
    let phone = phoneCountryCode ? `${phoneCountryCode}${phoneNumber}` : null

    return (
      <>
        <div className="card card-custom gutter-b">
          <div className="card-body">
            <div className="d-flex">
              {/*begin: Info*/}
              <div className="flex-grow-1">
                {/*begin::Title*/}
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  {/*begin::User*/}
                  <div className="mr-3">
                    <div className="d-flex align-items-center mr-3">
                      {/*begin::Name*/}
                      <a
                        href="javascript:;"
                        className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3"
                      >
                        Order #{order.id} - QR: {qr.slug}
                      </a>
                      {/*end::Name*/}
                      <span className="label label-light-success label-inline font-weight-bolder mr-1">
                        Payment Successful
                      </span>
                    </div>
                    {/*begin::Contacts*/}
                    <div className="d-flex flex-wrap my-2">
                      <a
                        href={`mailto:${email}`}
                        className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                          {/*begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Communication/Mail-notification.svg*/}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <path
                                d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                                fill="#000000"
                              />
                              <circle
                                fill="#000000"
                                opacity="0.3"
                                cx="19.5"
                                cy="17.5"
                                r="2.5"
                              />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                        { email }
                      </a>
                      { phone &&
                        <a
                          href={`tel:${phone}`}
                          className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                            {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-11-19-154210/theme/html/demo1/dist/../src/media/svg/icons/Devices/iPhone-X.svg*/}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x={0} y={0} width={24} height={24} />
                                <path
                                  d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                />
                                <path
                                  d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z M8,1 L16,1 C17.5187831,1 18.75,2.23121694 18.75,3.75 L18.75,20.25 C18.75,21.7687831 17.5187831,23 16,23 L8,23 C6.48121694,23 5.25,21.7687831 5.25,20.25 L5.25,3.75 C5.25,2.23121694 6.48121694,1 8,1 Z M9.5,1.75 L14.5,1.75 C14.7761424,1.75 15,1.97385763 15,2.25 L15,3.25 C15,3.52614237 14.7761424,3.75 14.5,3.75 L9.5,3.75 C9.22385763,3.75 9,3.52614237 9,3.25 L9,2.25 C9,1.97385763 9.22385763,1.75 9.5,1.75 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                />
                              </g>
                            </svg>
                            {/*end::Svg Icon*/}
                          </span>
                          { phone }
                        </a>
                      }
                    </div>
                    {/*end::Contacts*/}
                  </div>
                  {/*begin::User*/}
                  {/*begin::Actions*/}
                  <div className="mb-10 row">
                    <div className="mr-4">
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm print-btn"
                        onClick={() =>
                          this.setState({
                            showPrintQrModal: true,
                          })
                        }
                      >
                        Print
                      </button>
                    </div>
                    <div className="dropdown dropdown-inline">
                      <button
                        href="javascript:;"
                        className="btn btn-primary  btn-sm font-weight-bolder text-uppercase dropdown-toggle"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        disabled={isUpdating}
                      >
                        status: { order.status }
                      </button>
                      <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                        {/*begin::Navigation*/}
                        <ul className="navi navi-hover">
                          <li className="navi-header font-weight-bold py-4">
                            <span className="font-size-lg">Change Status:</span>
                          </li>
                          <li className="navi-separator mb-3 opacity-70" />
                          <li className="navi-item w-100">
                            <a
                              href="javascript:;"
                              className="navi-link w-100"
                              onClick={() => this._updateStatus("pending")}
                            >
                              <span className="navi-text">
                                <span className="label label-xl label-inline label-light-warning">
                                  Pending
                                </span>
                              </span>
                            </a>
                          </li>
                          <li className="navi-item">
                            <a
                              href="javascript:;"
                              className="navi-link"
                              onClick={() => this._updateStatus("out_for_delivery")}
                            >
                              <span className="navi-text">
                                <span className="label label-xl label-inline label-light-info">
                                  Out For Delivery
                                </span>
                              </span>
                            </a>
                          </li>
                          <li className="navi-item">
                            <a
                              href="javascript:;"
                              className="navi-link"
                              onClick={() => this._updateStatus("complete")}
                            >
                              <span className="navi-text">
                                <span className="label label-xl label-inline label-light-success">
                                  Complete
                                </span>
                              </span>
                            </a>
                          </li>
                          <li className="navi-item">
                            <a
                              href="javascript:;"
                              className="navi-link"
                              onClick={() => this._updateStatus("cancelled")}
                            >
                              <span className="navi-text">
                                <span className="label label-xl label-inline label-light-danger">
                                  Cancelled
                                </span>
                              </span>
                            </a>
                          </li>
                        </ul>
                        {/*end::Navigation*/}
                      </div>
                    </div>
                  </div>
                  {/*end::Actions*/}
                </div>
                {/*end::Title*/}
                {/*begin::Content*/}
                <div className="d-flex align-items-center flex-wrap justify-content-between">
                  {/*begin::Description*/}
                  <Address name="Billing Address" address={order.billing_address}/>
                  <Address name="Shipping Address" address={order.shipping_address}/>
                  {/*end::Description*/}
                </div>
                {/*end::Content*/}
              </div>
              {/*end::Info*/}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4">
            <Notice order={order} onUpdated={order => this.setState({ order })}/>
            <Progress order={order}/>
          </div>
          <div className="col-xl-8">
            {/*begin::Card*/}
            <div className="card card-custom gutter-b">
              {/*begin::Header*/}
              <div className="card-header">
                <div className="card-title">
                  <h3 className="card-label">Order Details</h3>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body px-0">
                {/*begin::Shopping Cart*/}
                <div className="table-responsive">
                  <table className="table">
                    {/*begin::Cart Header*/}
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th className="text-center">Qty</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      { order.items.map(item => {
                          return (
                            <tr>
                              <td className="d-flex align-items-center font-weight-bolder">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-60 flex-shrink-0 mr-4 bg-light">
                                  <img
                                    className="symbol-label"
                                    src={item.qr.image.thumbnail}
                                  />
                                </div>
                                {/*end::Symbol*/}
                                <a
                                  href="javascript:;"
                                  className="text-dark text-hover-primary"
                                >
                                  { item.merch.title }
                                </a>
                              </td>
                              <td className="text-center align-middle">
                                <span className="mr-2 font-weight-bolder">{ item.quantity }</span>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PrintQrCodesModal
          show={showPrintQrModal}
          qrs={[qr]}
          onHide={() =>
            this.setState({ selectedQrs: [], showPrintQrModal: false })
          }
        />
      </>
    )
  }
}
