import FetchHelper from "./FetchHelper";
import AuthManager from "./AuthManager";

export default class Backend {
  static updateClient(client, data) {
    return FetchHelper.patch(window.Api.Clients + "/" + client.user.id, data);
  }

  static getAllClientFees(client) {
    return FetchHelper.get(`${window.Api.ClientFees}?client_id=${client.user.id}&paginated=false`);
  }

  static updateClientFees(fees) {
    return FetchHelper.patch(window.Api.ClientFees + "/" + fees.id, fees);
  }

  static createClientFees(fees) {
    return FetchHelper.post(window.Api.ClientFees, fees);
  }

  static deleteClientFees(fees) {
    return FetchHelper.delete(window.Api.ClientFees + "/" + fees.id);
  }

  static createQr(qr) {
    let data = {
      name: "QR Code",
      payment_options: [
        {
          value: 500,
          default: true,
          percentage: false,
        },
        {
          value: 1000,
          default: false,
          percentage: false,
        },
        {
          value: 1500,
          default: false,
          percentage: false,
        },
        {
          value: 2000,
          default: false,
          percentage: false,
        },
        {
          value: 5,
          default: true,
          percentage: true,
        },
        {
          value: 10,
          default: false,
          percentage: true,
        },
        {
          value: 15,
          default: false,
          percentage: true,
        },
        {
          value: 20,
          default: false,
          percentage: true,
        },
      ],
      custom_payment_enabled: true,
      type: "tip",
      currency: 1,
      amount_default_view: true,
    };
    return FetchHelper.post(window.Api.QrCodes, data);
  }

  static createPrintablePDF(data) {
    let params = `sticker=${data.sticker}&business_card=${data.business_card}&poster=${data.poster}&qr_ids=${data.qr_ids}&email=${data.email}`
    return FetchHelper.get(`${window.Api.PrintQrCodes}?${params}`);
  }

  static uploadImage(image, type = "photo") {
    let data = new FormData();

    data.append("file", image, image.name);
    data.append("type", type);

    return FetchHelper.post(window.Api.Images, data, true);
  }
  static updateQr(qr) {
    return FetchHelper.patch(window.Api.QrCodes + "/" + qr.id, qr);
  }

  static restoreQr(qr) {
    return FetchHelper.patch(
      window.Api.QrCodes + "/" + qr.id + "?objects=all",
      { deleted_at: null }
    );
  }

  static deleteQr(qr) {
    return FetchHelper.delete(window.Api.QrCodes + "/" + qr.id);
  }

  static getGlobalFees(countryShort) {
    return FetchHelper.get(window.Api.GlobalFees + `?country_short=${countryShort}&paginated=false`)
  }

  static updateGlobalFees(fees) {
    return FetchHelper.patch(window.Api.GlobalFees + "/" + fees.id, fees);
  }

  static createGlobalFees(fees) {
    return FetchHelper.post(window.Api.GlobalFees, fees)
  }

  static deleteGlobalFees(fees) {
    return FetchHelper.delete(window.Api.GlobalFees + "/" + fees.id);
  }

  static getOrder(id) {
    return FetchHelper.get(window.Api.Orders + "/" + id);
  }

  static updateOrder(order) {
    return FetchHelper.patch(window.Api.Orders + "/" + order.id, {
      status: order.status,
    });
  }

  static updateNotice(order, notice) {
    return FetchHelper.patch(window.Api.Orders + "/" + order.id, { notice });
  }

  static createNotification(notification) {
    return FetchHelper.post(window.Api.Notifications, notification);
  }

  static updateNotification(notification) {
    return FetchHelper.patch(
      window.Api.Notifications + "/" + notification.id,
      notification
    );
  }

  static deleteNotification(notification) {
    return FetchHelper.delete(window.Api.Notifications + "/" + notification.id);
  }

  static resendNotification(notification) {
    return FetchHelper.post(window.Api.Notifications + "/resend", {
      notification_id: notification.id,
    });
  }

  static getAllGroupFees(qr) {
    return FetchHelper.get(`${window.Api.GroupFees}?group_id=${qr.group}&paginated=false`);
  }

  static updateGroupFees(fees) {
    return FetchHelper.patch(window.Api.GroupFees + "/" + fees.id, fees);
  }

  static createGroupFees(fees) {
    return FetchHelper.post(window.Api.GroupFees, fees);
  }

  static deleteGroupFees(fees) {
    return FetchHelper.delete(window.Api.GroupFees + "/" + fees.id);
  }

  static getPoolDescriptions(){
    return FetchHelper.get(`${window.Api.Settings}?keys=description_pool_n_split,description_pool_n_solo`)
    .then(response => response.results)
  }

  static updatePoolDescription(description){
    return FetchHelper.post(window.Api.Settings, description)
  }
}
