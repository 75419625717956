import React from 'react'

import StatCard from './components/chart/StatCard'

import ClientsTable from './components/tables/ClientsTable'

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'
import Currency from '../utils/Currency'

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      labels: [],
      stats: [],
      loading: true,
    }
  }

  componentDidMount(){
  }

  _renderStats(){
    let { stats } = this.state
    return stats.map((stat, index) => {
      return (
        <StatCard
          key={index}
          title={stat.title}
          subtitle={stat.subtitle}
          text={stat.text}
          onClick={() => {}}
        />
      )
    })
  }

  render() {
    let {
      stats,
    } = this.state

    return (
      <>
        <ClientsTable title="Latest Users" showQrCodesSubTable={true}/>
      </>
    )
  }
}
