import React from "react"

import moment from 'moment'

import General from '../../utils/General'

export default class Progess extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      order: props.order,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      order,
      showEditModal
    } = this.state

    let progresses = order.progresses.sort((a, b) => b.id - a.id)

    return (
      <>
      <div className="card card-custom">
        {/*begin::Header*/}
        <div className="card-header h-auto py-4">
          <div className="card-title">
            <h3 className="card-label">Order Progress</h3>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body py-4">
          {/*begin::Timeline*/}
          <div className="timeline timeline-3">
            <div className="timeline-items">
              { progresses.map((progress, index) => {
                  let statusChange = index != (order.progresses.length - 1)
                  return (
                    <div className="timeline-item">
                      <div className="timeline-content">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <div className="mr-2">
                            <a
                              href="javascript:;"
                              className="text-dark-75 text-hover-primary font-weight-bold"
                            >
                             {  statusChange ? "Status Changed" : "Order Placed" }
                            </a>
                            <span className="text-muted ml-2">{ moment(progress.created_at).fromNow() }</span>
                            { !statusChange &&
                              <span className="label label-light-success font-weight-bolder label-inline ml-2">
                                new
                              </span>
                            }
                          </div>
                        </div>
                        { statusChange &&
                          <p className="p-0">Status changed to { General.snakeCaseToTitleCase(progress.status) }</p>
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          {/*end::Timeline*/}
        </div>
        {/*end::Body*/}
      </div>
      {/*end::Card*/}
      </>
    )
  }
}
