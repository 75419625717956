import React from 'react'
import { toast } from 'react-toastify'

import NotificationsTable from './components/tables/NotificationsTable'

export default class Notifications extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <NotificationsTable />
      </div>
    )
  }
}
