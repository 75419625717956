import React from "react"

import moment from 'moment'

import NoticeModal from './modal/NoticeModal'

import General from '../../utils/General'

export default class Notice extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      order: props.order,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      order,
      showEditModal
    } = this.state

    return (
      <>
        <div className="card card-custom gutter-b">
          <div className="card-header h-auto py-3 border-0">
            <div className="card-title">
              <h3 className="card-label text-primary">Notice</h3>
            </div>
            <div className="card-toolbar">
              { order.notice_at &&
                <span className="label font-weight-bold label label-inline label-primary">
                  { moment(order.notice_at).fromNow() }
                </span>
              }
            </div>
          </div>
          <div className="card-body pt-2">
            <p className="text-dark-50">
              { order.notice }
            </p>
            <div className="mt-5">
              <a
                href="javascript:;"
                className="btn btn-outline-primary  w-100px btn-sm font-weight-bold mr-2"
                onClick={() => this.setState({ showEditModal: true })}
              >
                Edit
              </a>
            </div>
          </div>
        </div>
        <NoticeModal
          show={showEditModal}
          order={order}
          onUpdated={order => {
            this.setState({ showEditModal: false })
            this.props.onUpdated(order)
          }}
        />
      </>
    )
  }
}
