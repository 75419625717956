import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.Api = {};

window.Api.Base = env.REACT_APP_API_BASE

window.Api.User = {};

window.Api.User.Base = window.Api.Base + "/user"

window.Api.User.Login                 = window.Api.User.Base + "/login"
window.Api.User.Logout                = window.Api.User.Base + "/logout"
window.Api.User.Info                  = window.Api.User.Base + "/info"
window.Api.User.RequestResetPassword  = window.Api.User.Base + "/request-reset-password"
window.Api.User.ResetPassword         = window.Api.User.Base + "/reset-password"
window.Api.User.RefreshToken          = window.Api.User.Base + "/refresh-token"

window.Api.Register                   = window.Api.Base + "/clients"

window.Api.Clients                    = window.Api.Base + "/clients"
window.Api.ClientFees                 = window.Api.Base + "/client-fees"
window.Api.GlobalFees                 = window.Api.Base + "/global-fees"

window.Api.GroupFees                  = window.Api.Base + "/group-fees"

window.Api.Stats                      = window.Api.Base + "/stats"
window.Api.Notifications              = window.Api.Base + "/notifications"
window.Api.Orders                     = window.Api.Base + "/orders"
window.Api.QrCodes                    = window.Api.Base + "/qrs"
window.Api.PrintQrCodes               = window.Api.Base + "/qr-print"
window.Api.Settings                   = window.Api.Base + '/settings'
