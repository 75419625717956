import React from 'react'
import { withRouter } from 'react-router-dom'

import AuthManager from '../../utils/AuthManager'
import Backend from '../../utils/Backend'

const LINK_DASHBOARD = {
  title: 'Home',
  url: '/',
  type: 'link',
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path
        d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
        fill="#000000"
        fill-rule="nonzero"
      ></path>
      <path
        d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z"
        fill="#000000"
        fill-rule="nonzero"
        opacity="0.3"
      ></path>
    </g>
  ),
}

  const LINK_MANAGE_USERS = {
  title: 'Manage Users',
  url: '/manage-users',
  type: 'link',
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24"></polygon>
      <path
        d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
        fill="#000000"
        fill-rule="nonzero"
        opacity="0.3"
      ></path>
      <path
        d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
        fill="#000000"
        fill-rule="nonzero"
      ></path>
    </g>
  ),
}

const LINK_QR_CODES = {
  title: 'QR Codes',
  url: '/qr-codes',
  type: 'link',
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path
        d="M7.14319965,19.3575259 C7.67122143,19.7615175 8.25104409,20.1012165 8.87097532,20.3649307 L7.89205065,22.0604779 C7.61590828,22.5387706 7.00431787,22.7026457 6.52602525,22.4265033 C6.04773263,22.150361 5.88385747,21.5387706 6.15999985,21.0604779 L7.14319965,19.3575259 Z M15.1367085,20.3616573 C15.756345,20.0972995 16.3358198,19.7569961 16.8634386,19.3524415 L17.8320512,21.0301278 C18.1081936,21.5084204 17.9443184,22.1200108 17.4660258,22.3961532 C16.9877332,22.6722956 16.3761428,22.5084204 16.1000004,22.0301278 L15.1367085,20.3616573 Z"
        fill="#000000"
      ></path>
      <path
        d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z M19.068812,3.25407593 L20.8181344,5.00339833 C21.4039208,5.58918477 21.4039208,6.53893224 20.8181344,7.12471868 C20.2323479,7.71050512 19.2826005,7.71050512 18.696814,7.12471868 L16.9474916,5.37539627 C16.3617052,4.78960984 16.3617052,3.83986237 16.9474916,3.25407593 C17.5332781,2.66828949 18.4830255,2.66828949 19.068812,3.25407593 Z M5.29862906,2.88207799 C5.8844155,2.29629155 6.83416297,2.29629155 7.41994941,2.88207799 C8.00573585,3.46786443 8.00573585,4.4176119 7.41994941,5.00339833 L5.29862906,7.12471868 C4.71284263,7.71050512 3.76309516,7.71050512 3.17730872,7.12471868 C2.59152228,6.53893224 2.59152228,5.58918477 3.17730872,5.00339833 L5.29862906,2.88207799 Z"
        fill="#000000"
        opacity="0.3"
      ></path>
      <path
        d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z"
        fill="#000000"
      ></path>
    </g>
  ),
}


const LINK_GROUP_CODES = {
  title: 'Pool Party',
  url: '/pool-parties',
  type: 'link',
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path
        d="M7.14319965,19.3575259 C7.67122143,19.7615175 8.25104409,20.1012165 8.87097532,20.3649307 L7.89205065,22.0604779 C7.61590828,22.5387706 7.00431787,22.7026457 6.52602525,22.4265033 C6.04773263,22.150361 5.88385747,21.5387706 6.15999985,21.0604779 L7.14319965,19.3575259 Z M15.1367085,20.3616573 C15.756345,20.0972995 16.3358198,19.7569961 16.8634386,19.3524415 L17.8320512,21.0301278 C18.1081936,21.5084204 17.9443184,22.1200108 17.4660258,22.3961532 C16.9877332,22.6722956 16.3761428,22.5084204 16.1000004,22.0301278 L15.1367085,20.3616573 Z"
        fill="#000000"
      ></path>
      <path
        d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z M19.068812,3.25407593 L20.8181344,5.00339833 C21.4039208,5.58918477 21.4039208,6.53893224 20.8181344,7.12471868 C20.2323479,7.71050512 19.2826005,7.71050512 18.696814,7.12471868 L16.9474916,5.37539627 C16.3617052,4.78960984 16.3617052,3.83986237 16.9474916,3.25407593 C17.5332781,2.66828949 18.4830255,2.66828949 19.068812,3.25407593 Z M5.29862906,2.88207799 C5.8844155,2.29629155 6.83416297,2.29629155 7.41994941,2.88207799 C8.00573585,3.46786443 8.00573585,4.4176119 7.41994941,5.00339833 L5.29862906,7.12471868 C4.71284263,7.71050512 3.76309516,7.71050512 3.17730872,7.12471868 C2.59152228,6.53893224 2.59152228,5.58918477 3.17730872,5.00339833 L5.29862906,2.88207799 Z"
        fill="#000000"
        opacity="0.3"
      ></path>
      <path
        d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z"
        fill="#000000"
      ></path>
    </g>
  ),
}

const LINK_ORDERS = {
  title: 'Orders',
  url: '/orders',
  type: 'link',
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path
        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
        fill="#000000"
        opacity="0.3"
      ></path>
      <path
        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
        fill="#000000"
      ></path>
      <rect
        fill="#000000"
        opacity="0.3"
        x="10"
        y="9"
        width="7"
        height="2"
        rx="1"
      ></rect>
      <rect
        fill="#000000"
        opacity="0.3"
        x="7"
        y="9"
        width="2"
        height="2"
        rx="1"
      ></rect>
      <rect
        fill="#000000"
        opacity="0.3"
        x="7"
        y="13"
        width="2"
        height="2"
        rx="1"
      ></rect>
      <rect
        fill="#000000"
        opacity="0.3"
        x="10"
        y="13"
        width="7"
        height="2"
        rx="1"
      ></rect>
      <rect
        fill="#000000"
        opacity="0.3"
        x="7"
        y="17"
        width="2"
        height="2"
        rx="1"
      ></rect>
      <rect
        fill="#000000"
        opacity="0.3"
        x="10"
        y="17"
        width="7"
        height="2"
        rx="1"
      ></rect>
    </g>
  ),
}

const LINK_PAYMENT_SETTINGS = {
  title: 'Payment Settings',
  url: '/payment-settings',
  type: 'link',
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <rect
        fill="#000000"
        opacity="0.3"
        x="2"
        y="6"
        width="21"
        height="12"
        rx="6"
      />
      <circle fill="#000000" cx="17" cy="12" r="4" />
    </g>
  ),
}

const LINK_PUSH_NOTIFICATIONS = {
  title: 'Push Notifications',
  url: '/notifications',
  type: 'link',
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <rect
        fill="#000000"
        opacity="0.3"
        x="2"
        y="6"
        width="21"
        height="12"
        rx="6"
      />
      <circle fill="#000000" cx="17" cy="12" r="4" />
    </g>
  ),
}

const LINK_POOL_DESCRIPTIONS = {
  title: 'Pool Descriptions',
  url: '/pool-settings',
  type: 'link',
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <rect fill="#000000" opacity="0.3" x="4" y="5" width="16" height="2" rx="1"/>
        <rect fill="#000000" opacity="0.3" x="4" y="13" width="16" height="2" rx="1"/>
        <path d="M5,9 L13,9 C13.5522847,9 14,9.44771525 14,10 C14,10.5522847 13.5522847,11 13,11 L5,11 C4.44771525,11 4,10.5522847 4,10 C4,9.44771525 4.44771525,9 5,9 Z M5,17 L13,17 C13.5522847,17 14,17.4477153 14,18 C14,18.5522847 13.5522847,19 13,19 L5,19 C4.44771525,19 4,18.5522847 4,18 C4,17.4477153 4.44771525,17 5,17 Z" fill="#000000"/>
    </g>
  ),
}

const LINK_LOGOUT = {
  title: 'Log Out',
  url: '/account',
  type: 'link',
}

const SECTIONS = [
  {
    title: "",
    links: [LINK_DASHBOARD]
  },
  {
    title: 'USERS',
    links: [LINK_MANAGE_USERS, LINK_QR_CODES, LINK_GROUP_CODES],
  },
  {
    title: 'ADMIN',
    links: [LINK_ORDERS, LINK_PAYMENT_SETTINGS, LINK_PUSH_NOTIFICATIONS, LINK_POOL_DESCRIPTIONS],
  },
]

export const MENU_ITEMS = [
  LINK_DASHBOARD,
  LINK_MANAGE_USERS,
  LINK_QR_CODES,
  LINK_GROUP_CODES,
  LINK_ORDERS,
  LINK_PAYMENT_SETTINGS,
  LINK_PUSH_NOTIFICATIONS
]

class LeftMenu extends React.Component {
  constructor(props) {
    super(props)
    let sections = SECTIONS
    this.state = {
      sections,
      menuItems: MENU_ITEMS,
    }
  }

  componentDidMount() {
    this._updateActive()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this._updateActive()
    }
  }

  _updateActive() {
    let pathname = this.props.location.pathname
    let menuItems = this.state.menuItems.map((link) => {
      link.active = link.url == pathname
      return link
    })
    this.setState({ menuItems })
  }

  _onLinkPressed(link) {
    if (link == LINK_LOGOUT) {
      AuthManager.logout()
    }
    this.props.history.push(link.url)
  }

  _handleEditSiteClicked(e) {
    e.preventDefault()
    this.setState({ loadingHash: true })
    let website = AuthManager.currentWebsite

    let url =
      website.url ||
      'https://' + website.slug + '.' + window.General.WebsiteNakedDomain
    url += '?edit=true'

    Backend.createLoginHash()
      .then((hash) => {
        window.open(url + '&h=' + hash.value)
        this.setState({ loadingHash: false })
      })
      .catch((error) => {
        window.open(url)
        this.setState({ loadingHash: false })
      })
  }

  _handleStartSelling(e) {
    e.preventDefault()
    this._goTo('/shop/activate')
  }

  _goTo(url) {
    this.props.history.push(url)
  }

  _renderSections() {
    let { sections } = this.state
    return (
      <div
        className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
        id="kt_aside_menu_wrapper"
      >
        <div
          id="kt_aside_menu"
          className="kt-aside-menu"
          data-ktmenu-vertical="1"
          data-ktmenu-scroll="1"
          data-ktmenu-dropdown-timeout="500"
        >
          <ul className="kt-menu__nav">
            {sections.map((section, sectionIndex) => {
              return (
                <>
                  {section.title && (
                    <li className="kt-menu__section ">
                      <h4 className="kt-menu__section-text">{section.title}</h4>
                      <i className="kt-menu__section-icon flaticon-more-v2"></i>
                    </li>
                  )}
                  {section.links.map((link, linkIndex) => {
                    let className = 'kt-menu__item'
                    if (link.url === window.location.pathname) {
                      className += ' kt-menu__item--active'
                    }
                    return (
                      <li className={className} aria-haspopup="true">
                        <a
                          className="kt-menu__link"
                          onClick={() => this.props.history.push(link.url)}
                        >
                          <span className="kt-menu__link-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                              className="kt-svg-icon"
                            >
                              {link.icon}
                            </svg>
                          </span>
                          <span className="kt-menu__link-text">
                            {link.title}
                          </span>
                        </a>
                      </li>
                    )
                  })}
                </>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }

  render() {
    let { buttons, menuItems, loadingHash } = this.state

    return (
      <>
        {/* <button className="kt-aside-close " id="kt_aside_close_btn">
          <i className="la la-close">
          </i>
        </button> */}
        <div className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
          id="kt_aside"
        >
          <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand">
            <div className="kt-aside__brand-logo">
              <a onClick={() => this._goTo('/')} style={{ cursor: 'pointer' }}>
                <svg
                  width="118"
                  height="56"
                  viewBox="0 0 946 263"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M243.31 259L154.51 125.06L237.39 -1.7643e-05H173.38L122.32 76.96L71.26 -1.7643e-05H5.77L89.76 125.8L0.96 259H65.34L121.58 174.27L178.19 259H243.31ZM420.936 160.21C420.936 111.74 382.456 73.63 333.246 73.63C280.336 73.63 238.896 114.33 238.896 167.24C238.896 221.26 281.076 262.7 334.356 262.7C372.096 262.7 402.436 248.64 415.756 224.59L375.056 199.8C368.766 210.9 351.746 218.3 335.466 218.3C310.676 218.3 293.656 205.35 288.846 180.56H419.456C420.566 173.53 420.936 166.5 420.936 160.21ZM289.586 147.26C294.396 127.65 308.826 115.81 330.656 115.81C349.896 115.81 366.546 129.13 368.766 147.26H289.586ZM502.264 -1.7643e-05H450.094V259H502.264V-1.7643e-05ZM671.845 -1.7643e-05V103.23C659.265 84.36 639.655 73.63 614.125 73.63C567.135 73.63 529.765 115.81 529.765 167.98C529.765 220.15 567.135 262.7 614.125 262.7C639.655 262.7 659.265 251.97 671.845 233.1V259H724.015V-1.7643e-05H671.845ZM626.335 212.75C601.175 212.75 580.825 193.14 580.825 167.98C580.825 143.19 601.175 123.21 626.335 123.21C651.865 123.21 671.845 142.82 671.845 167.98C671.845 193.51 651.865 212.75 626.335 212.75ZM892.978 77.33V103.23C880.398 84.36 860.788 73.63 835.258 73.63C788.268 73.63 750.898 115.81 750.898 167.98C750.898 220.15 788.268 262.7 835.258 262.7C860.788 262.7 880.398 251.97 892.978 233.1V259H945.148V77.33H892.978ZM847.468 212.75C822.308 212.75 801.958 193.14 801.958 167.98C801.958 143.19 822.308 123.21 847.468 123.21C872.998 123.21 892.978 142.82 892.978 167.98C892.978 193.51 872.998 212.75 847.468 212.75Z"
                    fill="#9600FF"
                  />
                </svg>
              </a>

              {/*
                <WebsiteSelect/>
              */}
            </div>
            <div className="kt-aside__brand-tools">
              <button
                className="kt-aside__brand-aside-toggler"
                id="kt_aside_toggler"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    className="kt-svg-icon"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                        id="Path-94"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) "
                      />
                      <path
                        d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                        id="Path-94"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                        transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) "
                      />
                    </g>
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    className="kt-svg-icon"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                        id="Path-94"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                      <path
                        d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                        id="Path-94"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                        transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      />
                    </g>
                  </svg>
                </span>
              </button>
              {/*<button className="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left" id="kt_aside_toggler"><span></span></button> */}
            </div>
          </div>

          <div
            className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
            id="kt_aside_menu_wrapper"
          >
            <div
              id="kt_aside_menu"
              className="kt-aside-menu "
              data-ktmenu-vertical="1"
              data-ktmenu-scroll="1"
              data-ktmenu-dropdown-timeout="500"
            >
              <ul className="kt-menu__nav ">
                <div class="mobile-buttons" style={{ display: 'none' }}>
                  <li class="kt-menu__item">
                    <a
                      href="#"
                      class="btn btn-outline-brand btn-icon side-margin-20 m-b-20"
                      onClick={(e) => this._handleEditSiteClicked(e)}
                    >
                      <i class="fa fa-eye"></i>
                    </a>
                  </li>
                </div>

                {this._renderSections()}
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(LeftMenu)
