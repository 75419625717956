import React from "react"

import moment from 'moment'


const FEES = [
  {
    title: "Charge for person giving payment",
    base_key: "platform_transaction_tiper"
  },
  {
    title: "Payee Transaction Fee",
    base_key: "platform_transaction_tipee"
  },
  {
    title: "Withdrawal Fee",
    base_key: "platform_payout"
  },
  {
    title: "Monthly Fee",
    base_key: "platform_monthly"
  },
]

export default class Fees extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      fees: props.fees,
      hint: props.hint
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderFeeInput(title, base_key){
    let { fees, hint } = this.state

    let flat = fees[base_key+"_flat"] != null ?  fees[base_key+"_flat"] / 100 : ""
    let percentage = fees[base_key+"_percentage"] != null ? fees[base_key+"_percentage"] * 100 : ""


    if((fees.country_short || fees.client || fees.group) && !fees.default){
      if(base_key === "platform_payout" || base_key === "platform_monthly"){
        return null
      }
    }

    return (
      <div className="form-group row">
        <label className="col-4 col-form-label">{ title }</label>
        <div className="col-4">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              defaultValue={flat}
              onChange={e => {
                flat = e.target.value ? Math.round(parseFloat(e.target.value).toFixed(2) * 100) : null
                fees[base_key+"_flat"] = flat
                this.props.onUpdated(fees)
              }}
            />
            <div className="input-group-append">
              <button type="button" className="btn btn-secondary">
                Flat
              </button>
            </div>
          </div>
        </div>

        { base_key !== "platform_monthly" &&
          <div className="col-4">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                defaultValue={percentage}
                onChange={e => {
                  percentage = e.target.value ? parseFloat(parseFloat(e.target.value) / 100.0).toFixed(4) : null
                  fees[base_key+"_percentage"] = percentage
                  this.props.onUpdated(fees)
                }}
              />
              <div className="input-group-append">
                <button type="button" className="btn btn-secondary">
                  Percentage
                </button>
              </div>
            </div>
            { hint &&
              <span className="form-text text-muted ml-auto">{hint}</span>
            }
          </div>
        }
      </div>
    )
  }

  render() {
    let {
      fees,
    } = this.state

    return (
      <form className="form" id="feesForm">
      { fees &&
        <>
          { ((fees.country_short || fees.client || fees.group) && !fees.default) &&
            <>
              <div className="form-group row">
                <label className="col-4 col-form-label">Tip Amount</label>
                <div className="col-4">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-secondary">
                        From
                      </button>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={fees.min_tip ? fees.min_tip / 100 : ""}
                      onChange={e => {
                        fees.min_tip = e.target.value ? Math.round(parseFloat(e.target.value).toFixed(2) * 100) : null
                        this.setState({ fees })
                      }}
                    />
                  </div>
                </div>

                <div className="col-4">
                  <div className="input-group">

                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-secondary">
                        To
                      </button>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={fees.max_tip ? fees.max_tip / 100 : ""}
                      onChange={e => {
                        fees.max_tip = e.target.value ? Math.round(parseFloat(e.target.value).toFixed(2) * 100) : null
                        this.setState({ fees })
                      }}
                    />
                  </div>
                </div>
              </div>

              <div class="c-separator form-group row mt-4 align-items-center text-center">
                <div class="col-3">
                  <hr/>
                </div>
                <div class="col-6">
                  <span>
                    <strong>
                      Fees
                    </strong>
                  </span>
                </div>
                <div class="col-3">
                  <hr/>
                </div>
              </div>
            </>
          }
          {
            FEES.map(fee => {
              return this._renderFeeInput(fee.title, fee.base_key)
            })
          }
        </>
      }
      </form>
    )
  }
}
