import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import LeftMenu from './components/LeftMenu'
import MobileHeader from './components/MobileHeader'
import Footer from './components/Footer'
import QuickPanel from './components/QuickPanel'
import Header from './components/Header'
import SubHeader from './components/SubHeader'

import Dashboard from './Dashboard'
import ManageUsers from './ManageUsers'
import QrCodes from './QrCodes'
import GroupCodes from './GroupCodes'
import Orders from './Orders'
import Order from './Order'
import PaymentSettings from './PaymentSettings'
import Notifications from './Notifications'
import Settings from './Settings'
import PoolSettings from './PoolSettings'

import AuthManager from '../utils/AuthManager'

export default class App extends React.Component {

  render() {
    let subscriptionAlertText = 'Upgrade to a Pro Account to save your changes.'
    if (window.location.href.indexOf('subscribe') === -1) {
      subscriptionAlertText = 'This is a Pro feature, please upgrade to Pro.'
    }

    subscriptionAlertText +=
      ' Choose a Once Off payment, or a low Monthly payment.'

    return (
      <>
        <MobileHeader />

        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <LeftMenu />
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              <Header />

              <div id="kt-content-top" />

              {/* begin:: Content */}
              <div
                className="kt-content  kt-grid__item kt-grid__item--fluid"
                id="kt_content"
              >
                <div
                  className={
                    window.location.pathname == '/leads'
                      ? 'board-container'
                      : 'kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid'
                  }
                >

                  <Switch>
                    {/* }<Route exact path="/" component={Overview} /> */}
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/manage-users" component={ManageUsers} />
                    <Route exact path="/qr-codes" component={QrCodes} />
                    <Route exact path="/pool-parties" component={GroupCodes} />
                    <Route exact path="/orders" component={Orders} />
                    <Route exact path="/orders/:orderId" component={Order} />
                    <Route exact path="/payment-settings" component={PaymentSettings} />
                    <Route exact path="/pool-settings" component={PoolSettings} />
                    <Route exact path="/notifications" component={Notifications} />
                    <Route exact path="/settings" component={Settings} />
                    <Redirect to="/404" />
                  </Switch>
                </div>

                {/*  end:: Content  */}
              </div>
              {/*  begin:: Footer  */}
              <Footer />
              {/*  end:: Footer  */}
            </div>
          </div>
          {/*  end:: Page  */}
          {/*  begin::Quick Panel  */}
          <QuickPanel />
          {/*  end::Quick Panel  */}
          {/*  begin::Scrolltop  */}
          <div id="kt_scrolltop" className="kt-scrolltop">
            <i className="fa fa-arrow-up" />
          </div>
        </div>
      </>
    )
  }
}
