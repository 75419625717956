import React, { Spinner } from 'react'

import Select from './Select'

import { components } from 'react-select'

import Website from '../../../utils/Website'
import AuthManager, { KEY_WEBSITE } from '../../../utils/AuthManager'
import AsyncStorage from '../../../utils/AsyncStorage'

const SingleValue = ({ children, ...props }) => {
  console.log('aaa', props)
  console.log('abc', children)

  let img = require('../../../assets/media/logos/Xelda.svg')
  return (
    <div
      style={{
        maxHeight: 30,
      }}
    >
      <a
        onClick={() => this._goTo('/')}
        style={{ cursor: 'pointer', float: 'left' }}
      >
        <img alt="Logo" src={img} height="20px" />
      </a>
      <input disabled value={props.data.label} style={{ border: 'none' }} />
    </div>
  )
}

export default class WebsiteSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  componentDidMount() {}

  _renderOptions() {
    let { value } = this.state

    return (
      <>
        {AuthManager.websites.map((option) => {
          return <option value={option.id}>{option.name} aaa</option>
        })}
      </>
    )
  }

  render() {
    let { value } = this.state

    let website = {
      value: AuthManager.currentWebsite.id,
      label: Website.getCleanDomain(AuthManager.currentWebsite),
    }

    let className = this.props.wide ? 'website-selector-wide' : ''
    return (
      <Select
        value={website}
        className={`async-select-paginate website-selector ${className}`}
        classNamePrefix="async-select-paginate"
        name="website"
        placeholder="Website"
        onChange={(option) => {
          let website = AuthManager.websites.find(
            (site) => site.id === option.value
          )
          console.log('sel', website)
          AsyncStorage.setItem(KEY_WEBSITE, JSON.stringify(website)).then(
            () => {
              window.location.reload()
            }
          )
        }}
        options={AuthManager.websites.map((website) => ({
          value: website.id,
          label: Website.getCleanDomain(website),
          data: website,
        }))}
        components={{ SingleValue }}
      />
    )
  }
}

WebsiteSelect.defaultProps = {
  className: 'country-select',
}
