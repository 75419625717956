import React from "react"

import moment from "moment"

import { withRouter } from "react-router-dom";

import BaseTable from "./BaseTable"

import ConfirmModal from '../modal/ConfirmModal';
import NotificationModal from '../modal/NotificationModal'

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import Currency from "../../../utils/Currency";

import Notify from "../../../utils/Notify";

const USER_ROLE_FILTERS = {
  name: {
    api: "role",
    display: "Type"
  },
  values: [
      {
        label: 'All',
        value: null,
      },
      {
        label: 'Individual',
        value: 'client',
      },
      {
        label: 'Charity',
        value: 'charity',
      },
      {
        label: 'Restaurant',
        value: 'restaurant',
      },
  ]
}

class NotificationsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _deleteNotification(){
    let { selectedNotification } = this.state

    this.setState({ isUpdating: true })
    Backend.deleteNotification(selectedNotification)
    .then(() => {
      this.table.current.refresh()
      this.setState({
        isUpdating: false,
        selectedNotification: null,
        showConfirmDeleteModal: false
      })
      Notify.success("Notification deleted!")
    })
    .catch(error => {
      this.setState({ isUpdating: false })
      Notify.success(error.message)
    })
  }

  _resendNotification(){
    let { selectedNotification } = this.state

    this.setState({ isUpdating: true })
    Backend.resendNotification(selectedNotification)
    .then(() => {
      this.setState({
        isUpdating: false,
        selectedNotification: null,
        showConfirmResendModal: false
      })
      Notify.success("Notification resent!")
    })
    .catch(error => {
      this.setState({ isUpdating: false })
      Notify.success(error.message)
    })
  }

  _getColumns() {
    return [
      {
        Header: 'Notification',
        accessor: "title"
      },
      {
        Header: 'Frequency',
        id: 'Frequency',
        Cell: rowInfo => {
          let notification = rowInfo.original
          return General.snakeCaseToTitleCase(notification.frequency)
        }
      },
      {
        Header: 'TYPE',
        id: 'role',
        Cell: rowInfo => {
          let notification = rowInfo.original

          let labelClass = "label-danger"
          let textClass = "text-danger"
          let type = "Individual"
          return (
            <>
              <span class={`label ${labelClass} label-dot`}>
              </span>
              &nbsp;
              <span class={`font-weight-bold ${textClass}`}>
                  { type }
              </span>
            </>
          )
        }
      },
      {
        Header: 'Requirements',
        id: 'requirement',
        Cell: rowInfo => {
          let notification = rowInfo.original
          return General.snakeCaseToTitleCase(notification.requirement)
        }
      },
      {
        Header: 'Actions',
        orderable: false,
        id: 'status',
        Cell: rowInfo => {
          let notification = rowInfo.original

          return (
            <div className="dropdown">
              <a
                href="javascript:;"
                className="btn btn-sm btn-clean btn-icon btn-icon-md"
                data-toggle="dropdown">
                  <i className="la la-ellipsis-v"></i>
              </a>

              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <ul className="navi flex-column navi-hover py-2">
                  <li class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                    Choose an action:
                  </li>
                  <li class="navi-item">
                    <a
                      href="#"
                      class="navi-link"
                      onClick={() => this.setState({
                        selectedNotification: notification,
                        showNotificationModal: true
                      })}
                    >
                      <span class="navi-icon">
                        <i class="la la-money-check-alt"></i>
                      </span>
                      <span class="navi-text">Edit Notification</span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a
                      href="#"
                      class="navi-link"
                      onClick={() => this.setState({
                        selectedNotification: notification,
                        showConfirmDeleteModal: true
                      })}
                    >
                      <span class="navi-icon">
                        <i class="la fab la-wpforms"></i>
                      </span>
                      <span class="navi-text">Delete Notification</span>
                    </a>
                  </li>
                  { notification.frequency === "once_off" &&
                    <li class="navi-item">
                      <a
                        href="#"
                        class="navi-link"
                        onClick={() => this.setState({
                          selectedNotification: notification,
                          showConfirmResendModal: true
                        })}
                      >
                        <span class="navi-icon">
                          <i class="la la-pause-circle-o"></i>
                        </span>
                        <span class="navi-text">
                          Resend
                        </span>
                      </a>
                    </li>
                  }
                </ul>
              </div>
            </div>
          )
        }
      }
    ]
  }

  render() {
    let {
      isUpdating,
      selectedNotification,
      showNotificationModal,
      showConfirmDeleteModal,
      showConfirmResendModal
    } = this.state

    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          ref={this.table}
          title={this.props.title}
          endpoint={window.Api.Notifications}
          noDataMessage={"No notifications found"}
          title={this.props.title}
          columns={columns}
          exportButtonsEnabled={false}
          showObjectFilter={false}
          filters={[]}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={(rowInfo) => {
            return (
              <div class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-wrapper">
                  &nbsp;
                  <button
                    type="button"
                    class="btn btn-light-primary font-weight-bolder"
                    onClick={() => this.setState({ selectedNotification: null, showNotificationModal: true })}
                  >
                    <i class="flaticon2-plus"></i>
                    Add Notification
                  </button>
                </div>
              </div>
            )
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
        />
        <NotificationModal
          show={showNotificationModal}
          notification={selectedNotification}
          onCancel={() => this.setState({ selectedNotification: null, showNotificationModal: false})}
          onUpdated={() => {
            this.table.current.refresh()
            this.setState({ selectedNotification: null, showNotificationModal: false})
          }}
        />
        <ConfirmModal
          show={showConfirmDeleteModal}
          loading={isUpdating}
          message={"You cannot undo this action"}
          cancelButtonText="No, Cancel"
          confirmButtonText={"Yes, Delete"}
          onCancel={() =>
            this.setState({ selectedNotification: null, showConfirmDeleteModal: false })
          }
          onConfirm={() => this._deleteNotification(selectedNotification)}
        />
        <ConfirmModal
          show={showConfirmResendModal}
          loading={isUpdating}
          message={"You cannot undo this action"}
          cancelButtonText="No, Cancel"
          confirmButtonText={"Yes, Resend"}
          onCancel={() =>
            this.setState({ selectedNotification: null, showConfirmResendModal: false })
          }
          onConfirm={() => this._resendNotification(selectedNotification)}
        />
      </div>
    )
  }
}


NotificationsTable.defaultProps = {
  title: 'All Notifications',
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
}

export default withRouter(NotificationsTable);
