import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Notify from "../../../utils/Notify"
import Backend from "../../../utils/Backend"

export default class NoticeModal extends React.Component {
  constructor(props){
    super(props)

    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      notice: props.order.notice
    }
  }

  _update(){
    let {
      order,
      notice
    } = this.state

    this.setState({ isUpdating: true })
    Backend.updateNotice(order, notice)
    .then(order => {
      this.setState({ isUpdating: false})
      this.props.onUpdated(order)
      Notify.success("Update Order")
    })
    .catch(error => {
      this.setState({ isUpdating: false})
      Notify.error(error.message)
    })
  }

  render() {
    let {
      show,
      order,
      notice,
      isUpdating
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => {
          if(!isUpdating){
            this.props.onCancel()
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Notice</Modal.Title>
        </Modal.Header>


        <Modal.Body>
          <div className="form-group">
            <label>Notice</label>
            <textarea
              type="text"
              className="form-control form-control-lg"
              name="notice"
              value={ notice }
              onChange={e => this.setState({ notice: e.target.value})}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            disabled={isUpdating}
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={isUpdating}
            onClick={() => this._update()}
          >
            Save Changes
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

NoticeModal.defaultProps = {

}
