import React from 'react'
import { toast } from 'react-toastify'

import OrdersTable from './components/tables/OrdersTable'

export default class Orders extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <OrdersTable />
      </div>
    )
  }
}
