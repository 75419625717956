import React from "react"

import moment from 'moment'

import General from '../../utils/General'

export default class Address extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      name: props.name,
      address: props.address,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      name,
      address,
      showEditModal
    } = this.state

    let keys = [
      "line_1",
      "line_2",
      "line_3",
      "city",
      "state",
      "country",
      "postal_code"
    ]

    return (
      <>
        <div className="flex-grow-1 font-weight-bold text-dark-50 py-2 py-lg-2 mr-5">
          <h6>{ name }</h6>
          { keys.map((key, index) => {
              if(!address[key]){
                return null
              }
              let isLast = index === keys.length - 1
              return (
                <>
                  { address[key] }{ isLast ? "" : "," }
                  <br />
                </>
              )
            })
          }
        </div>
      </>
    )
  }
}
