import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify"

import Select from '../common/Select'

const NOTIFICATION_ACTIONS = [
  {
    label: "KYC Setup",
    value: "kyc_setup"
  },
  {
    label: "Home",
    value: "home"
  },
  {
    label: "Settings",
    value: "settings"
  }
]

const NOTIFICATION_FREQUENCIES = [
  {
    label: "Once Off",
    value: "once_off"
  },
  {
    label: "Daily",
    value: "daily"
  },
  {
    label: "Weekly",
    value: "weekly"
  },
  {
    label: "Monthly",
    value: "monthly"
  },
]

const NOTIFICATION_REQUIREMENTS = [
  {
    label: "Unverified KYC Status",
    value: "unverified_kyc_status"
  },
  {
    label: "Payouts Disabled",
    value: "payouts_disabled"
  },
  {
    label: "No QR Created",
    value: "no_qr_created"
  },
  {
    label: "No Bank Account",
    value: "no_bank_account"
  },
]

export default class NotificationModal extends React.Component {
  constructor(props){
    super(props)

    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      show: props.show,
      notification: props.notification || {
        description: null
      }
    }
  }

  _isFormValid(){
    let { notification } = this.state

    let error = null
    if(!notification.title){
      error = "Please add a title"
    }
    else if(!notification.description){
      error = "Please add a description"
    }
    else if(!notification.action){
      error = "Please add a link"
    }
    else if(!notification.frequency){
      error = "Please specify a frequency"
    }
    else if(!notification.requirement){
      error = "Please specify a requirement"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _save(){
    let { notification } = this.state

    if(!this._isFormValid()){
      return true
    }

    this.setState({ loading: true })

    this._handleBackendCall(notification)
    .then(notification => {
      this.setState({ loading: false, notification: null })
      this.props.onUpdated()
      Notify.success("Notification updated")
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _handleBackendCall(notification){
    if(notification.id){
      return Backend.updateNotification(notification)
    }

    return Backend.createNotification(notification)
  }

  render() {
    let {
      show,
      loading,
      notification
    } = this.state

    if(!show || !notification){
      return null
    }

    return (
      <Modal
        show={show}
        size="lg"
        onHide={() => {
          if(!loading){
            this.props.onCancel()
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{ this.props.notification ? 'Edit' : 'Add' } Push Notification Below</Modal.Title>
        </Modal.Header>


        <Modal.Body>

        <div className="form-group row">
          <label className="col-3 col-form-label">Notification Title</label>
          <div className="col-9">
            <input
              type="text"
              className="form-control"
              value={notification.title}
              onChange={e => {
                notification.title = e.target.value
                this.setState({ notification })
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Notification Description</label>
          <div className="col-9">
            <textarea
              className="form-control"
              value={notification.description}
              onChange={e => {
                notification.description = e.target.value
                this.setState({ notification })
              }}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">Notification Link</label>
          <div className="col-9">
            <Select
              value={NOTIFICATION_ACTIONS.find(action => action.value === notification.action)}
              className="async-select-paginate"
              classNamePrefix="async-select-paginate"
              placeholder="Please Select An Option"
              onChange={option => {
                notification.action = option.value
                this.setState({ notification })
              }}
              options={NOTIFICATION_ACTIONS}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">Frequency</label>
          <div className="col-9">
            <Select
              value={NOTIFICATION_FREQUENCIES.find(action => action.value === notification.frequency)}
              className="async-select-paginate"
              classNamePrefix="async-select-paginate"
              placeholder="Please Select An Option"
              isDisabled={this.props.notification != null}
              onChange={option => {
                notification.frequency = option.value
                this.setState({ notification })
              }}
              options={NOTIFICATION_FREQUENCIES}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Requirement</label>
          <div className="col-9">
            <Select
              value={NOTIFICATION_REQUIREMENTS.find(action => action.value === notification.requirement)}
              className="async-select-paginate"
              classNamePrefix="async-select-paginate"
              placeholder="Please Select An Option"
              onChange={option => {
                notification.requirement = option.value
                this.setState({ notification })
              }}
              options={NOTIFICATION_REQUIREMENTS}
            />
          </div>
        </div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            disabled={loading}
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={loading}
            onClick={() => this._save()}
          >
            Save Changes
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

NotificationModal.defaultProps = {
    title: 'Are you sure?',
    message: null,
    loading: false,
    buttonClassName: 'primary',
    confirmButtonText: 'Confirm',
    cancelButtonText: "Cancel"
}
